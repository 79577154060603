var fieldName = function (name) { return name; };
export var defaultTableColumns = function () {
    return [
        {
            field: fieldName("name"),
            headerName: "Name",
            cellStyle: { textAlign: "left" },
            width: 200,
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            colId: "type",
            field: fieldName("rule"),
            headerName: "Type",
            cellStyle: { textAlign: "left" },
            cellRenderer: "TypeFormatter",
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            field: fieldName("active"),
            headerName: "Status",
            cellStyle: { textAlign: "left" },
            cellRenderer: "StatusFormatter",
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            colId: "rule",
            field: fieldName("rule"),
            headerName: "Rules",
            cellStyle: { textAlign: "left" },
            cellRenderer: "RulesFormatter",
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            field: fieldName("recipientCount"),
            headerName: "Recipients",
            cellStyle: { textAlign: "left" },
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            field: fieldName("sendEmail"),
            headerName: "Email",
            cellStyle: { textAlign: "left" },
            cellRenderer: "EmailFormatter",
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            field: fieldName("createdBy"),
            headerName: "Created by",
            cellStyle: { textAlign: "left" },
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            field: fieldName("createdAt"),
            headerName: "Created on",
            type: "numericColumn",
            cellStyle: { textAlign: "right" },
            sort: null,
            hide: false,
            pinned: null,
            valueFormatter: function (params) { return new Date(params.value); },
            //   columnFormatters.LastUpdatedFormatter(params.value, null, null, {
            //     locale: userSettings.language,
            //     timestampFormat: 'milliseconds',
            //   }),
        },
        {
            field: fieldName("modifiedBy"),
            headerName: "Last modified by",
            cellStyle: { textAlign: "left" },
            sort: null,
            hide: false,
            pinned: null,
        },
        {
            field: fieldName("updatedAt"),
            headerName: "Updated at",
            type: "numericColumn",
            cellStyle: { textAlign: "right" },
            sort: null,
            hide: false,
            pinned: null,
            valueFormatter: function (params) { return new Date(params.value); },
            //   columnFormatters.LastUpdatedFormatter(params.value, null, null, {
            //     locale: userSettings.language,
            //     timestampFormat: 'milliseconds',
            //   }),
        },
        {
            field: "actions",
            headerName: "",
            cellStyle: { textAlign: "center" },
            sort: null,
            hide: false,
            pinned: "right",
            cellRenderer: "NotificationsTableActions",
            width: 41 + 17 * 2,
        },
    ];
};
