export var initialState = {
    assetListParameters: {
        filter: {
            searchText: "",
            companies: [],
            expanded: [],
        },
        selectedAlarm: null,
        selectedView: "SplitView",
        sortOrder: "asc",
        sortField: "asset_id",
        pageNumber: 1,
        pageSize: 5,
    },
    userSettings: {
        language: "en-US",
        timezone: "GMT+2:00",
        temperature: "C",
        distance: "KM",
        volume: "L",
        speed: "KPH",
        assetListColumns: null,
        commandHistoryColumns: null,
        geofenceListColumns: null,
        temperatureChartColumns: null,
        assetHistoryColumns: null,
        deviceCommissioningSensorsColumns: null,
        deviceCommissioningDatacoldSensorsColumns: null,
        notificationsColumns: null,
    },
    deviceManagementSettings: {
        deviceProvisioningSelectedRow: {},
        provisioningType: "batch",
        deviceName: "",
        deviceSerial: "",
        deviceIMEI: "",
        deviceICCID: "",
        deviceId: "",
        deviceFlespiId: "",
        selectedPage: "provisioning",
    },
    companyManagement: {
        currentTab: 0,
    },
    assetReportManagement: {
        reportName: "",
        tempChart: false,
        legend: false,
        table: false,
        chartPng: "",
        eventsPng: "",
        legendData: "",
        tableData: "",
        startDate: "",
        endDate: "",
        asset: null,
        tenant: null,
        pdfOutput: "",
        tableHistory: null,
        pageOrientation: "portrait",
    },
    legendSettings: {
        events: {},
    },
    assetHistoryChartSelectedView: null,
};
