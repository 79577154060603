import { DeviceManagementPage } from "../components";
import DeviceManagementIconAssetUrl from "../assets/device-management-icon.svg";
export var routes = [
    {
        path: "/manage/device",
        exact: true,
        treatAsProtectedWhenAuthenticationIsAvailable: true,
        component: DeviceManagementPage,
        navigationItem: {
            label: "Device Management",
            icon: DeviceManagementIconAssetUrl,
            weight: 40000,
        },
    },
    {
        redirect: true,
        from: "/manage/device/commission",
        to: "/manage/device",
        treatAsProtectedWhenAuthenticationIsAvailable: true,
    },
];
