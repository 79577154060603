import React from "react";
import { useFormikContext } from "formik";
import TextField from "@carrier/fds-react/TextField";
import Typography from "@carrier/fds-react/Typography";
import Grid from "@carrier/fds-react/Grid";
import FormControlLabel from "@carrier/fds-react/FormControlLabel";
import Checkbox from "@carrier/fds-react/Checkbox";
// @ts-ignore
import SetTime from "@carrier/fds-react/patterns/SetTime/SetTime";
import Box from "@carrier/fds-react/Box";
import { StepSubHeader } from "../../../NotificatioForm/Steps/StepSubHeader";
import { RuleConditions } from "../../../NotificatioForm/Steps/RulesStep/RuleConditions";
import { ExtraRules } from "../../../NotificatioForm/Steps/RulesStep/ExtraRules";
import { typography } from "@/theme/constants";
export var RulesStep = function () {
    var placeholder = "Notification Name";
    var _a = useFormikContext(), values = _a.values, errors = _a.errors, handleChange = _a.handleChange, setFieldValue = _a.setFieldValue;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepSubHeader, { title: "Create rules for this notification" }),
        React.createElement(TextField, { placeholder: placeholder, "aria-label": placeholder, size: "xsmall", sx: { padding: "16px 0 24px", minWidth: "360px" }, color: "primary", onChange: function (e) {
                setFieldValue("name", e.currentTarget.value);
            }, value: values.name, error: Boolean(errors.name), helperText: errors.name }),
        React.createElement(Box, { sx: { fontSize: typography.h6.fontSize, padding: 0 } },
            "Send a notification if\u00A0",
            React.createElement(Box, { sx: { fontSize: typography.h5.fontSize, display: "inline-block" } },
                React.createElement("strong", null, "all")),
            "\u00A0of the conditions are met:"),
        React.createElement(RuleConditions, { fieldName: "conditions", conditions: values.conditions, error: errors.conditions, allowedEvents: [
                "TEMPERATURE_DEVIATION",
                "DOOR",
                "GEOFENCE",
                "TRU_ALARM",
            ] }),
        values.conditions.length > 0 &&
            !["DOOR", "TRU_ALARM"].includes(values.conditions[0].type) && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body1", gutterBottom: true }, "Except when Any of the conditions are met:"),
            React.createElement(RuleConditions, { fieldName: "exceptConditions", conditions: values.exceptConditions, allowedEvents: ["DOOR"] }))),
        React.createElement(ExtraRules, null),
        React.createElement(Grid, { container: true, alignItems: "center", sx: { paddingLeft: "8px" } },
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: values.enableTimeCondition, onChange: handleChange, name: "enableTimeCondition", size: "small" }), label: "Only send notification when the above conditions are met for" }),
            React.createElement(SetTime, { limitHours: 11, limitMinutes: 59, stepMinutes: 1, value: values.time, onChange: function (data) {
                    setFieldValue("time", data);
                } }))));
};
