import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import Typography from "@carrier/fds-react/Typography";
import { Dialog } from "../../../NotificatioForm/Event/Dialog";
import { Dropdown } from "../../../NotificatioForm/Dropdown/Dropdown";
import { GeofenceSelector } from "../../../NotificatioForm/Selector/Geofence";
import { ActionButtons } from "../../../NotificatioForm/Event/Modals/ActionButtons";
import styles from "./styles.module.scss";
import { useDropdownOption } from "@/features/notifications/hooks/useDropdownOptions";
export function GeofenceDialog(_a) {
    var _b, _c;
    var open = _a.open, handleCancel = _a.handleCancel, handleOk = _a.handleOk, initialValue = _a.initialValue;
    var initialGeofences = ((_b = initialValue === null || initialValue === void 0 ? void 0 : initialValue.expression) === null || _b === void 0 ? void 0 : _b.geofences) || [];
    var _d = useState(initialGeofences), selectedGeofences = _d[0], setSelectedGeofences = _d[1];
    var handleGeofencesChange = function (event, value) {
        setSelectedGeofences(value.map(function (_a) {
            var id = _a.id;
            return id;
        }));
    };
    var dropdownItems = useMemo(function () { return [
        {
            label: "Inside",
            value: "INSIDE",
        },
        {
            label: "Outside",
            value: "OUTSIDE",
        },
    ]; }, []);
    var initialComparison = ((_c = initialValue === null || initialValue === void 0 ? void 0 : initialValue.expression) === null || _c === void 0 ? void 0 : _c.comparison) ||
        dropdownItems[0].value;
    var _e = useDropdownOption(initialComparison), option = _e.option, handleOptionChange = _e.handleOptionChange;
    var handleSave = useCallback(function () {
        handleOk({
            type: "GEOFENCE",
            expression: {
                comparison: option,
                geofences: selectedGeofences,
            },
        });
    }, [handleOk, option, selectedGeofences]);
    return (React.createElement(Dialog, { open: open, onClose: handleCancel, dialogTitle: "GeofenceGroups", fullWidth: true, maxWidth: "sm", content: React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: clsx(styles.textBlock, styles.geofenceRule), variant: "body1", component: "span" },
                React.createElement("div", null, "If"),
                React.createElement(Dropdown, { value: option, onChange: handleOptionChange, items: dropdownItems }),
                React.createElement("div", null, "any of the geofence")),
            React.createElement(GeofenceSelector, { selectedIds: selectedGeofences, onChange: handleGeofencesChange })), actions: React.createElement(ActionButtons, { handleCancel: handleCancel, handleSave: handleSave }) }));
}
