var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import { useFormikContext } from "formik";
import { useOktaAuth } from "@okta/okta-react";
import Checkbox from "@carrier/fds-react/Checkbox";
import InputLabel from "@carrier/fds-react/InputLabel";
import Switch from "@carrier/fds-react/Switch";
import Typography from "@carrier/fds-react/Typography";
import FormControlLabel from "@carrier/fds-react/FormControlLabel";
import { RecipientSelector } from "../../Selector/RecipientSelector";
import { StepSubHeader } from "../../..//NotificatioForm/Steps/StepSubHeader";
export var ChooseDeliveryStep = function () {
    var _a, _b;
    var _c = useFormikContext(), values = _c.values, handleChange = _c.handleChange;
    var authState = useOktaAuth().authState;
    var userEmail = (_b = (_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.email;
    var _d = useState(userEmail ? values.recipients.includes(userEmail) : false), sendCopyToMyself = _d[0], setSendCopyToMyself = _d[1];
    var handleRecipientsChange = function (event, value) {
        setSendCopyToMyself(userEmail ? value.includes(userEmail) : false);
        handleChange({
            target: {
                name: "recipients",
                value: value,
            },
        });
    };
    var handleSendToMyselfChange = function (event, checked) {
        var newRecipients = __spreadArray([], values.recipients, true);
        if (userEmail) {
            if (checked) {
                newRecipients.push(userEmail);
            }
            else {
                newRecipients = newRecipients.filter(function (recipient) { return recipient !== userEmail; });
            }
        }
        handleChange({
            target: {
                name: "recipients",
                value: newRecipients,
            },
        });
        setSendCopyToMyself(checked);
    };
    return (React.createElement("div", null,
        React.createElement(StepSubHeader, { title: "Take this action when notification is triggered" }),
        React.createElement(InputLabel, { sx: { margin: "15px 0 24px 0" } },
            React.createElement(Switch, { sx: { margin: "0 10px 0 14px" }, name: "sendEmail", checked: values.sendEmail, disabled: true, size: "small", onChange: handleChange }),
            React.createElement(Typography, { variant: "body1", component: "span" }, "Send Email")),
        React.createElement(StepSubHeader, { title: "Recipients" }),
        React.createElement(RecipientSelector, { value: values.recipients, onChange: handleRecipientsChange, sx: { maxWidth: "450px", marginTop: "23px" } }),
        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { name: "sendCopyToMyself", checked: sendCopyToMyself, onChange: handleSendToMyselfChange }), label: "Send a copy to myself" })));
};
