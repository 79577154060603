import { AdvancedCompanyManagementPage } from "../components";
import AdvancedCompanyManagementPageIconAssetUrl from "../assets/advanced-company-management-icon.svg";
export var routes = [
    {
        path: "/manage/company_advanced",
        exact: true,
        treatAsProtectedWhenAuthenticationIsAvailable: true,
        component: AdvancedCompanyManagementPage,
        navigationItem: {
            label: "Advanced Company Management",
            icon: AdvancedCompanyManagementPageIconAssetUrl,
            weight: 80000,
        },
    },
];
