import React from "react";
import clsx from "clsx";
import { Chip, createStyles, makeStyles } from "@material-ui/core";
var useStyles = makeStyles(function () {
    return createStyles({
        inactive: {
            borderRadius: 4,
            color: "#000",
        },
        active: {
            backgroundColor: "#DEDBF0",
            color: "#1B079C",
        },
        root: {
            height: 24,
        },
        label: {
            fontSize: 14,
        },
    });
});
export function StatusFormatter(_a) {
    var _b;
    var value = _a.value;
    // const { t } = useTranslation();
    var t = function (x) { return x; };
    var classes = useStyles();
    var label = value
        ? t("Active" /*'common.active'*/)
        : t("Inactive" /*'common.inactive'*/);
    return (React.createElement(Chip, { classes: { root: classes.root, label: classes.label }, className: clsx(classes.inactive, (_b = {}, _b[classes.active] = value, _b)), label: label }));
}
