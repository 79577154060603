var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { getTenantInfo, getUserInfo } from "@/features/authentication/api";
export var fetchUserData = createAsyncThunk("auth/fetchUserData", function (email, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, userErrors, userData, tenantId, _c, tenantErrors, tenantData, error_1;
        var _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    _j.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, getUserInfo(email)];
                case 1:
                    _b = _j.sent(), userErrors = _b.errors, userData = _b.data;
                    if (userErrors) {
                        throw new Error((_e = (_d = userErrors[0]) === null || _d === void 0 ? void 0 : _d.message) !== null && _e !== void 0 ? _e : userErrors);
                    }
                    if (!(userData === null || userData === void 0 ? void 0 : userData.getUser)) {
                        throw new Error("User data is undefined");
                    }
                    tenantId = userData.getUser.tenantId;
                    if (!tenantId) {
                        throw new Error("User data has an undefined Tenant ID");
                    }
                    return [4 /*yield*/, getTenantInfo(tenantId)];
                case 2:
                    _c = _j.sent(), tenantErrors = _c.errors, tenantData = _c.data;
                    if (tenantErrors) {
                        console.error("tenantQueryResponse. Errors: ", tenantErrors);
                        throw new Error((_g = (_f = tenantErrors[0]) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : userErrors);
                    }
                    if (!(tenantData === null || tenantData === void 0 ? void 0 : tenantData.getTenantById)) {
                        throw new Error("Tenant data is undefined");
                    }
                    return [2 /*return*/, {
                            user: userData.getUser,
                            tenant: tenantData.getTenantById,
                        }];
                case 3:
                    error_1 = _j.sent();
                    return [2 /*return*/, rejectWithValue((_h = error_1) === null || _h === void 0 ? void 0 : _h.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var slice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        tenant: null,
        loading: false,
    },
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(fetchUserData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            var _b = payload, user = _b.user, tenant = _b.tenant;
            state.user = user;
            state.tenant = tenant;
            state.loading = false;
        });
        builder.addCase(fetchUserData.rejected, function (state) {
            state.loading = false;
        });
        builder.addCase(fetchUserData.pending, function (state) {
            state.loading = true;
        });
    },
});
export var authReducer = slice.reducer;
