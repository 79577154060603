var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
export function parsePackageJsonFeatures() {
    var packageJson = require("../../package.json");
    var features = packageJson["carrier-io"].features;
    var carrierFeatures = Object.keys(features).filter(function (featureName) { return features[featureName]; });
    var featureModules = carrierFeatures.map(function (featureName) {
        return require("../features/".concat(featureName, "/index.tsx")).featureModule({});
    });
    return {
        carrierFeatures: carrierFeatures,
        featureModules: featureModules,
    };
}
export function checkAuthenticationCapabilities(featureModules) {
    var authenticationCapabilitiesEnabled = false;
    featureModules.forEach(function (featureModule) {
        if (!authenticationCapabilitiesEnabled &&
            "providesAuthenticationCapabilities" in featureModule) {
            authenticationCapabilitiesEnabled = true;
        }
    });
    return authenticationCapabilitiesEnabled;
}
export function getRoutesWithNavItem(_a) {
    var featureModules = _a.featureModules, carrierFeatures = _a.carrierFeatures;
    var routesWithNavItems = [];
    var externalLink = [];
    featureModules.forEach(function (featureModule, index) {
        if ("routes" in featureModule) {
            featureModule.routes.forEach(function (featureModuleRoute) {
                if (!("redirect" in featureModuleRoute &&
                    featureModuleRoute.redirect === true)) {
                    var featureModuleName = carrierFeatures[index];
                    if ("navigationItem" in featureModuleRoute) {
                        routesWithNavItems.push(__assign(__assign({}, featureModuleRoute), { moduleName: featureModuleName }));
                    }
                    else if ("external" in featureModuleRoute) {
                        externalLink.push(featureModuleName);
                    }
                }
            });
        }
    });
    return {
        routesWithNavItems: routesWithNavItems,
        externalLink: externalLink,
    };
}
export function getNavigation(routesWithNavItems) {
    return routesWithNavItems
        .sort(function (a, b) { return a.navigationItem.weight - b.navigationItem.weight; })
        .map(function (_a) {
        var path = _a.path, moduleName = _a.moduleName, _b = _a.navigationItem, icon = _b.icon, label = _b.label;
        return ({
            icon: React.createElement("img", { src: icon, alt: label }),
            key: moduleName + path,
            label: label,
            path: path,
        });
    });
}
