import React from "react";
import { useHistory } from "react-router-dom";
// FDS
import FdsBreadcrumbs from "@carrier/fds-react/Breadcrumbs";
import FdsLink from "@carrier/fds-react/Link";
// MUI
import { makeStyles } from "@mui/styles";
import { BreadcrumbsBack } from "./BreadcrumbsBack";
import { BreadcrumbsSeparator } from "./BreadcrumbsSeparator";
var useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
    },
    link: {
        // Note: color will come from themeConfig
        color: "#b5b5c3",
        textTransform: "uppercase",
        textDecoration: "none",
        fontSize: "13px",
        "&:hover": {
            // Note: color will come from themeConfig
            color: "#270adc",
            textDecoration: "none",
        },
    },
    dot: {
        // Note: color will come from themeConfig
        color: "#d1d3e0",
        fontSize: "28px",
        lineHeight: "initial",
    },
});
export var Breadcrumbs = function (_a) {
    var items = _a.items, separator = _a.separator;
    var classes = useStyles();
    var history = useHistory();
    var handleClick = function (path) {
        if (path !== window.location.pathname) {
            history.push(path);
        }
    };
    return (React.createElement(FdsBreadcrumbs, { "aria-label": "breadcrumb", separator: separator !== null && separator !== void 0 ? separator : React.createElement(BreadcrumbsSeparator, { className: classes.dot }), className: classes.root }, items.map(function (item) {
        return item.root ? (React.createElement(BreadcrumbsBack, { key: item.pathname, path: item })) : (React.createElement(FdsLink, { key: item.pathname, underline: "hover", color: "inherit", href: "javascript:;", onClick: function () { return handleClick(item.pathname); }, className: classes.link, sx: item.sx }, item.title));
    })));
};
