// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e7jzGG_RTS5mSZp8x0GA {\n  font-size: 17px;\n  margin-bottom: 16px;\n}\n\n.dP42COxHOnhIXf2bHBP0 {\n  font-weight: bold;\n  line-height: 24px;\n}\n\n.LofE3zhvsE8MkIItmmFq {\n  text-transform: lowercase;\n}\n\n.zrM4ISPtd3uy36IgIzAZ {\n  margin-left: 12px;\n}", "",{"version":3,"sources":["webpack://./src/features/notifications/components/NotificatioForm/Steps/ReviewStep/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF;;AACA;EACE,iBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,yBAAA;AAGF;;AADA;EACE,iBAAA;AAIF","sourcesContent":[".text {\n  font-size: 17px;\n  margin-bottom: 16px;\n}\n.boldText {\n  font-weight: bold;\n  line-height: 24px;\n}\n.lowercaseText {\n  text-transform: lowercase;\n}\n.listItem {\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "e7jzGG_RTS5mSZp8x0GA",
	"boldText": "dP42COxHOnhIXf2bHBP0",
	"lowercaseText": "LofE3zhvsE8MkIItmmFq",
	"listItem": "zrM4ISPtd3uy36IgIzAZ"
};
export default ___CSS_LOADER_EXPORT___;
