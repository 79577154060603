var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Button from "@carrier/fds-react/Button";
import CircularProgress from "@carrier/fds-react/CircularProgress";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import { getUserApiKey, createUserApiKey, deleteUserApiKey } from "../../api";
import { bootstrapColors } from "@/theme/constants";
export var ExternalApiKey = function () {
    var _a;
    var authState = useOktaAuth().authState;
    var _b = useState(true), hidden = _b[0], setHidden = _b[1];
    var _c = useState(null), key = _c[0], setKey = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), error = _e[0], setError = _e[1];
    var loadKey = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var email, getResponse, _a, data, getApiKeyError, _b, createResponse, _c, data, createApiKeyError, _d;
        var _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    email = (_e = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _e === void 0 ? void 0 : _e.claims.email;
                    if (!email) {
                        setError(true);
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getUserApiKey(email)];
                case 2:
                    getResponse = _f.sent();
                    _a = getResponse.data.getApiKey, data = _a.data, getApiKeyError = _a.error;
                    if (getApiKeyError === null) {
                        setKey(data[0].key);
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    _b = _f.sent();
                    setError(true);
                    setLoading(false);
                    return [2 /*return*/];
                case 4:
                    _f.trys.push([4, 6, , 7]);
                    return [4 /*yield*/, createUserApiKey(email)];
                case 5:
                    createResponse = _f.sent();
                    _c = createResponse.data.createApiKey, data = _c.data, createApiKeyError = _c.error;
                    if (createApiKeyError === null) {
                        setKey(data.key);
                    }
                    else {
                        setError(true);
                    }
                    setLoading(false);
                    return [3 /*break*/, 7];
                case 6:
                    _d = _f.sent();
                    setError(true);
                    setLoading(false);
                    return [2 /*return*/];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [(_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims.email]);
    var showKey = function () { return setHidden(false); };
    var hideKey = function () { return setHidden(true); };
    var generateNewKey = function () { return __awaiter(void 0, void 0, void 0, function () {
        var email, deleteResponse, deleteApiKeyError, _a, createResponse, _b, createData, createError, e_1;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    email = (_c = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _c === void 0 ? void 0 : _c.claims.email;
                    if (typeof email === "undefined") {
                        return [2 /*return*/];
                    }
                    setKey(null);
                    setLoading(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, deleteUserApiKey(email)];
                case 2:
                    deleteResponse = _d.sent();
                    deleteApiKeyError = deleteResponse.data.deleteApiKey.error;
                    if (deleteApiKeyError !== null) {
                        setLoading(false);
                        setError(true);
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    _a = _d.sent();
                    setError(true);
                    setLoading(false);
                    return [2 /*return*/];
                case 4:
                    _d.trys.push([4, 6, , 7]);
                    return [4 /*yield*/, createUserApiKey(email)];
                case 5:
                    createResponse = _d.sent();
                    _b = createResponse.data.createApiKey, createData = _b.data, createError = _b.error;
                    if (createError === null) {
                        setKey(createData.key);
                    }
                    else {
                        setError(true);
                    }
                    setLoading(false);
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _d.sent();
                    setLoading(false);
                    setError(true);
                    return [2 /*return*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        loadKey();
    }, [loadKey]);
    if (loading) {
        return (React.createElement(Grid, { container: true, justifyContent: "center", sx: { marginTop: "1rem" } },
            React.createElement(CircularProgress, { size: "1rem", color: "primary" })));
    }
    return (React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "center", sx: { marginTop: "1.75rem" } },
        React.createElement(Grid, { container: true, sx: { marginBottom: "0.25rem" } },
            React.createElement(Typography, { variant: "body2" },
                React.createElement("strong", null, "External API Key"))),
        error ? (React.createElement(Grid, { container: true },
            React.createElement(Typography, { variant: "caption", color: bootstrapColors.Error.dark },
                React.createElement("strong", null, "API Key cannot be loaded")))) : (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true },
                React.createElement(Typography, { variant: "caption", sx: { fontSize: "9px" } },
                    React.createElement("strong", null, hidden ? "************" : key))),
            React.createElement(Grid, { container: true, justifyContent: "flex-end", sx: { marginTop: "0.75rem" } },
                hidden && (React.createElement(Button, { size: "large", onClick: showKey },
                    React.createElement(Typography, { variant: "caption" },
                        React.createElement("strong", null, "Show Key")))),
                !hidden && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: generateNewKey, color: "inherit", sx: {
                            marginRight: "0.75rem",
                        }, size: "large" },
                        React.createElement(Typography, { variant: "caption" },
                            React.createElement("strong", null, "Generate New Key"))),
                    React.createElement(Button, { onClick: hideKey, size: "large" },
                        React.createElement(Typography, { variant: "caption" },
                            React.createElement("strong", null, "Hide Key"))))))))));
};
