var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gql } from "@apollo/client";
import { slice } from "../stores";
import { getNotificationClient } from "@/utils/apollo/apolloClients";
var client = getNotificationClient();
var actions = slice.actions;
export function getNotifications() {
    return __awaiter(this, void 0, void 0, function () {
        var getNotificationsQuery;
        return __generator(this, function (_a) {
            getNotificationsQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query PageNotifications {\n      pageNotifications {\n        success\n        error\n        scanLimit\n        count\n        scannedCount\n        lastKey {\n          pk\n          sk\n        }\n        docs {\n          notificationId\n          name\n          active\n          recipientCount\n          sendEmail\n          rule {\n            condition {\n              type\n              expression\n            }\n            exceptConditions {\n              type\n              expression\n            }\n            onlySendWhenConditionMetActive\n            onlySendWhenConditionMetSeconds\n          }\n          createdBy\n          modifiedBy\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  "], ["\n    query PageNotifications {\n      pageNotifications {\n        success\n        error\n        scanLimit\n        count\n        scannedCount\n        lastKey {\n          pk\n          sk\n        }\n        docs {\n          notificationId\n          name\n          active\n          recipientCount\n          sendEmail\n          rule {\n            condition {\n              type\n              expression\n            }\n            exceptConditions {\n              type\n              expression\n            }\n            onlySendWhenConditionMetActive\n            onlySendWhenConditionMetSeconds\n          }\n          createdBy\n          modifiedBy\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  "])));
            return [2 /*return*/, client.query({
                    query: getNotificationsQuery,
                    variables: {},
                    fetchPolicy: "no-cache",
                })];
        });
    });
}
export var fetchNotifications = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        getNotifications()
            .then(function (_a) {
            var data = _a.data;
            if (!data.pageNotifications.success) {
                throw new Error(data.pageNotifications.error);
            }
            return dispatch(actions.notificationsFetched({
                entities: data.pageNotifications.docs,
            }));
        })
            .catch(function (error) {
            // TODO: when snackbar store will add, uncomment it
            // dispatch(
            //   snackbarActions.messageShow({
            //     message: error.message || error,
            //     requireTranslation: false,
            //     severity: 'error',
            //   })
            // );
            console.error(error);
            throw error.message || error;
        });
        return [2 /*return*/];
    });
}); }; };
var getCompaniesQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query PageCompanies {\n    pageCompanies {\n      success\n      error\n      scanLimit\n      count\n      scannedCount\n      lastKey {\n        pk\n        sk\n      }\n      docs {\n        id\n        parentId\n        name\n      }\n    }\n  }\n"], ["\n  query PageCompanies {\n    pageCompanies {\n      success\n      error\n      scanLimit\n      count\n      scannedCount\n      lastKey {\n        pk\n        sk\n      }\n      docs {\n        id\n        parentId\n        name\n      }\n    }\n  }\n"])));
var getRecipientsQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query PageRecipients {\n    pageRecipients {\n      success\n      error\n      scanLimit\n      count\n      scannedCount\n      lastKey {\n        pk\n        sk\n      }\n      docs {\n        email\n        firstName\n        lastName\n        phone\n      }\n    }\n  }\n"], ["\n  query PageRecipients {\n    pageRecipients {\n      success\n      error\n      scanLimit\n      count\n      scannedCount\n      lastKey {\n        pk\n        sk\n      }\n      docs {\n        email\n        firstName\n        lastName\n        phone\n      }\n    }\n  }\n"])));
var createNotificationMutation = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation CreateNotification($doc: CreateNotificationInput!) {\n    createNotification(doc: $doc) {\n      success\n      error\n      doc {\n        notificationId\n        name\n        active\n        recipientCount\n        sendEmail\n        recipients\n        rule {\n          condition {\n            type\n            expression\n          }\n          exceptConditions {\n            type\n            expression\n          }\n          onlySendWhenConditionMetActive\n          onlySendWhenConditionMetSeconds\n        }\n        createdAt\n        updatedAt\n        createdBy\n        modifiedBy\n      }\n    }\n  }\n"], ["\n  mutation CreateNotification($doc: CreateNotificationInput!) {\n    createNotification(doc: $doc) {\n      success\n      error\n      doc {\n        notificationId\n        name\n        active\n        recipientCount\n        sendEmail\n        recipients\n        rule {\n          condition {\n            type\n            expression\n          }\n          exceptConditions {\n            type\n            expression\n          }\n          onlySendWhenConditionMetActive\n          onlySendWhenConditionMetSeconds\n        }\n        createdAt\n        updatedAt\n        createdBy\n        modifiedBy\n      }\n    }\n  }\n"])));
export function createNotification(input) {
    return client.mutate({
        mutation: createNotificationMutation,
        variables: input,
        fetchPolicy: "no-cache",
    });
}
export function getRecipients() {
    return client.query({
        query: getRecipientsQuery,
        variables: {},
        fetchPolicy: "no-cache",
    });
}
export function getCompanies() {
    return client.query({
        query: getCompaniesQuery,
        variables: {},
        fetchPolicy: "no-cache",
    });
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
