import { CompanyManagementPage } from "../components/companyManagementPage";
import CompanyManagementIconAssetUrl from "../assets/company-management-icon.svg";
export var routes = [
    {
        path: "/manage/company",
        exact: true,
        treatAsProtectedWhenAuthenticationIsAvailable: true,
        component: CompanyManagementPage,
        navigationItem: {
            label: "Company Management",
            icon: CompanyManagementIconAssetUrl,
            weight: 30000,
        },
    },
];
