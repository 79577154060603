var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloLink } from "@apollo/client";
import { OKTA_TOKEN } from "@/constants";
export var authMiddleware = new ApolloLink(function (operation, forward) {
    var oktaTokenStorage = localStorage.getItem(OKTA_TOKEN);
    var token = "";
    if (oktaTokenStorage) {
        token = JSON.parse(oktaTokenStorage).accessToken.accessToken;
    }
    // add the authorization to the headers
    operation.setContext(function (_a) {
        var _b = _a.headers, headers = _b === void 0 ? {} : _b;
        return ({
            headers: __assign(__assign({}, headers), { authorization: token }),
        });
    });
    return forward(operation);
});
