import { additionColors, bootstrapColors } from "@/theme/constants/colors";
import { ColorsPaletteEnum } from "@/theme/types/colors";
export var themeOverwrittenComponents = {
    MuiLinearProgress: {
        styleOverrides: {
            root: {
                backgroundColor: additionColors.deliver,
                "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: bootstrapColors[ColorsPaletteEnum.Primary].main,
                },
            },
        },
    },
    MuiTreeItem: {
        styleOverrides: {
            root: {
                "& .Mui-selected": {
                    backgroundColor: "transparent !important",
                    fontWeight: "bold",
                },
            },
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                background: "rgba(0,0,0,.1)",
            },
        },
    },
};
