import React from "react";
import Grid from "@carrier/fds-react/Grid";
import Button from "@carrier/fds-react/Button";
import Typography from "@carrier/fds-react/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { STEP_LABELS } from "@/features/notifications/constants";
export var ModalHeader = function (_a) {
    var onClose = _a.onClose, currentStep = _a.currentStep;
    return (React.createElement(Grid, { container: true, sx: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            padding: "6px 12px 6px 24px",
        }, justifyContent: "space-between", alignItems: "center" },
        React.createElement(Typography, { variant: "h6" },
            React.createElement("strong", null,
                "Add Notification ",
                currentStep + 1,
                " of ",
                STEP_LABELS.length)),
        React.createElement(Button, { variant: "text", color: "secondary", onClick: onClose, sx: {
                minWidth: "auto",
                borderRadius: "50%",
                padding: "12px",
                color: "#9e9e9e",
            }, size: "small" },
            React.createElement(CloseIcon, null))));
};
