import React, { useCallback, useMemo } from "react";
import Typography from "@carrier/fds-react/Typography";
import { Dialog } from "../../../NotificatioForm/Event/Dialog";
import { Dropdown } from "../../../NotificatioForm/Dropdown/Dropdown";
import { ActionButtons } from "../../../NotificatioForm/Event/Modals/ActionButtons";
import styles from "./styles.module.scss";
import { useDropdownOption } from "@/features/notifications/hooks/useDropdownOptions";
export var AlarmDialog = function (_a) {
    var _b;
    var open = _a.open, handleCancel = _a.handleCancel, handleOk = _a.handleOk, initialValue = _a.initialValue;
    var dropdownItems = useMemo(function () { return [
        {
            label: "Active shutdown",
            value: "ACTIVE_SHUTDOWN",
        },
    ]; }, []);
    var initialComparison = ((_b = initialValue === null || initialValue === void 0 ? void 0 : initialValue.expression) === null || _b === void 0 ? void 0 : _b.comparison) ||
        dropdownItems[0].value;
    var _c = useDropdownOption(initialComparison), option = _c.option, handleOptionChange = _c.handleOptionChange;
    var handleSave = useCallback(function () {
        handleOk({
            type: "TRU_ALARM",
            expression: {
                comparison: option,
            },
        });
    }, [handleOk, option]);
    return (React.createElement(Dialog, { open: open, onClose: handleCancel, dialogTitle: "Active alarms", fullWidth: true, maxWidth: "sm", content: React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: styles.textBlock, variant: "body1", component: "span" },
                React.createElement("div", null, "If the alarm, "),
                React.createElement(Dropdown, { value: option, onChange: handleOptionChange, items: dropdownItems }),
                React.createElement("div", null, "is active"))), actions: React.createElement(ActionButtons, { handleCancel: handleCancel, handleSave: handleSave }) }));
};
