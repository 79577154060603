export var TypographyEnum;
(function (TypographyEnum) {
    TypographyEnum["H1"] = "h1";
    TypographyEnum["H2"] = "h2";
    TypographyEnum["H3"] = "h3";
    TypographyEnum["H4"] = "h4";
    TypographyEnum["H5"] = "h5";
    TypographyEnum["H6"] = "h6";
    TypographyEnum["Subtitle1"] = "Subtitle1";
    TypographyEnum["Subtitle2"] = "Subtitle2";
    TypographyEnum["Body1"] = "Body1";
    TypographyEnum["Body2"] = "Body2";
    TypographyEnum["Caption"] = "Caption";
    TypographyEnum["Overline"] = "Overline";
    TypographyEnum["ButtonLarge"] = "ButtonLarge";
    TypographyEnum["ButtonMedium"] = "ButtonMedium";
    TypographyEnum["ButtonSmall"] = "ButtonSmall";
    TypographyEnum["AvatarLetter"] = "AvatarLetter";
    TypographyEnum["InputLabel"] = "InputLabel";
    TypographyEnum["HelperText"] = "HelperText";
    TypographyEnum["InputText1"] = "InputText1";
    TypographyEnum["InputText2"] = "InputText2";
    TypographyEnum["Badge"] = "Badge";
    TypographyEnum["Chip"] = "Chip";
    TypographyEnum["Tooltip"] = "Tooltip";
    TypographyEnum["XSLabel"] = "XSLabel";
})(TypographyEnum || (TypographyEnum = {}));
export var TransformEnum;
(function (TransformEnum) {
    TransformEnum["Capitalize"] = "capitalize";
    TransformEnum["Uppercase"] = "uppercase";
    TransformEnum["Lowercase"] = "lowercase";
    TransformEnum["None"] = "None";
})(TransformEnum || (TransformEnum = {}));
export var WeightEnum;
(function (WeightEnum) {
    WeightEnum[WeightEnum["Light"] = 200] = "Light";
    WeightEnum[WeightEnum["Book"] = 300] = "Book";
    WeightEnum[WeightEnum["Regular"] = 400] = "Regular";
    WeightEnum[WeightEnum["Medium"] = 500] = "Medium";
    WeightEnum[WeightEnum["SemiBold"] = 600] = "SemiBold";
    WeightEnum[WeightEnum["Bold"] = 700] = "Bold";
})(WeightEnum || (WeightEnum = {}));
export var FontUnit;
(function (FontUnit) {
    FontUnit["Px"] = "px";
    FontUnit["Em"] = "em";
    FontUnit["Rem"] = "rem";
})(FontUnit || (FontUnit = {}));
