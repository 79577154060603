import React, { useState } from "react";
// TODO: import Select from FDS when task https://carrier-digital.atlassian.net/browse/CARRIERIO-1320 will be implemented
import { Select } from "@material-ui/core";
import MenuItem from "@carrier/fds-react/MenuItem";
import styles from "./styles.module.scss";
export var Dropdown = function (_a) {
    var value = _a.value, items = _a.items, onChange = _a.onChange;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var handleChange = function (event) {
        onChange(event.target.value);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    return (React.createElement(Select, { open: open, onClose: handleClose, onOpen: handleOpen, value: value, onChange: handleChange, className: styles.selectContainer, classes: { root: styles.selectRoot, icon: styles.selectIcon } }, items.map(function (item) { return (React.createElement(MenuItem, { value: item.value, key: item.value }, item.label)); })));
};
