export var CompanyType;
(function (CompanyType) {
    CompanyType["CarrierWarehouse"] = "CarrierWarehouse";
    CompanyType["Customer"] = "Customer";
    CompanyType["Dealer"] = "Dealer";
    CompanyType["Distributor"] = "Distributor";
    CompanyType["ServicePartner"] = "ServicePartner";
})(CompanyType || (CompanyType = {}));
export var TemperatureType;
(function (TemperatureType) {
    TemperatureType["C"] = "C";
    TemperatureType["F"] = "F";
})(TemperatureType || (TemperatureType = {}));
export var DistanceType;
(function (DistanceType) {
    DistanceType["Km"] = "KM";
    DistanceType["Mi"] = "MI";
})(DistanceType || (DistanceType = {}));
export var VolumeType;
(function (VolumeType) {
    VolumeType["L"] = "L";
    VolumeType["G"] = "G";
})(VolumeType || (VolumeType = {}));
export var SpeedType;
(function (SpeedType) {
    SpeedType["Kph"] = "KPH";
    SpeedType["Mph"] = "MPH";
})(SpeedType || (SpeedType = {}));
export var CompanyTreeItemType;
(function (CompanyTreeItemType) {
    CompanyTreeItemType["NONE"] = "NONE";
    CompanyTreeItemType["GLOBAL"] = "GLOBAL";
    CompanyTreeItemType["REGION"] = "REGION";
    CompanyTreeItemType["COUNTRY"] = "COUNTRY";
    CompanyTreeItemType["COMPANY"] = "COMPANY";
    CompanyTreeItemType["FLEET"] = "FLEET";
})(CompanyTreeItemType || (CompanyTreeItemType = {}));
