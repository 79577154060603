var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from "react";
import Autocomplete from "@carrier/fds-react/Autocomplete";
import TextField from "@carrier/fds-react/TextField";
import { useTypedSelector } from "@/utils";
export var GeofenceSelector = function (_a) {
    var selectedIds = _a.selectedIds, onChange = _a.onChange, rest = __rest(_a, ["selectedIds", "onChange"]);
    var _b = useTypedSelector(function (state) { return state.geofences; }), entities = _b.entities, loading = _b.loading;
    var _c = useTypedSelector(function (state) { return state.geofenceGroups; }), groups = _c.entities, isGroupsLoading = _c.loading;
    var ungroupedTitle = "Ungrouped";
    var options = useMemo(function () {
        return (entities === null || entities === void 0 ? void 0 : entities.map(function (geofence) {
            var _a;
            return ({
                id: geofence.geofenceId,
                name: geofence.name,
                groupName: ((_a = groups === null || groups === void 0 ? void 0 : groups.find(function (group) { return group.groupId === geofence.groupId; })) === null || _a === void 0 ? void 0 : _a.name) ||
                    ungroupedTitle,
            });
        }).sort(function (a, b) {
            var compareGroupNames = a.groupName.localeCompare(b.groupName);
            if (compareGroupNames === 0) {
                return a.name.localeCompare(b.name);
            }
            return compareGroupNames;
        })) || [];
    }, [entities, groups, ungroupedTitle]);
    var value = (entities === null || entities === void 0 ? void 0 : entities.filter(function (geofence) { return selectedIds.includes(geofence.geofenceId); }).map(function (geofence) {
        var _a;
        return ({
            id: geofence.geofenceId,
            name: geofence.name,
            groupName: ((_a = groups === null || groups === void 0 ? void 0 : groups.find(function (group) { return group.groupId === geofence.groupId; })) === null || _a === void 0 ? void 0 : _a.name) ||
                ungroupedTitle,
        });
    })) || [];
    return (React.createElement(Autocomplete, __assign({ multiple: true, limitTags: 1, options: options, groupBy: function (option) { return option.groupName; }, getOptionLabel: function (option) { return option.name; }, value: value, loading: loading || isGroupsLoading, onChange: onChange, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { placeholder: value && value.length ? undefined : "Select geofences" }))); } }, rest)));
};
