import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@mui/styles";
import Button from "@carrier/fds-react/Button";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import Link from "@carrier/fds-react/Link";
import { MailNotificationIcon } from "@/features/user-profile/components/icons/MailNotificationIcon";
import { useLogout } from "@/hooks/useLogout";
import { bootstrapColors } from "@/theme/constants";
var useStyles = makeStyles(function () {
    var linkColor = "#b5b5c3";
    var hoverLinkColor = bootstrapColors.Primary.main;
    return {
        profileEmailLink: {
            color: linkColor,
            "&:hover": {
                color: hoverLinkColor,
            },
        },
        logoutBtn: {
            width: "100%",
            backgroundColor: "#e1f0ff",
            color: bootstrapColors.Primary.main,
            "&:hover": {
                color: bootstrapColors.Primary.contrast,
            },
        },
        mailIcon: {
            color: linkColor,
            marginRight: "0.25rem",
            "&:hover": {
                color: hoverLinkColor,
            },
        },
    };
});
export var UserInfo = function () {
    var _a, _b, _c, _d;
    var authState = useOktaAuth().authState;
    var classes = useStyles();
    var _e = useLogout(), logout = _e.logout, loading = _e.loading;
    return (React.createElement(Grid, null,
        React.createElement(Typography, { variant: "body2" },
            React.createElement("strong", null, (_b = (_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.name)),
        React.createElement(Grid, { sx: { marginTop: "0.5rem" }, container: true, direction: "row", alignItems: "center" },
            React.createElement(Grid, null,
                React.createElement(MailNotificationIcon, { color: "primary", classes: { colorPrimary: classes.mailIcon } })),
            React.createElement(Grid, null,
                React.createElement(Link, { href: "#", variant: "caption", underline: "none", className: classes.profileEmailLink },
                    React.createElement(Typography, { variant: "caption" }, (_d = (_c = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _c === void 0 ? void 0 : _c.claims) === null || _d === void 0 ? void 0 : _d.email)))),
        React.createElement(Grid, { container: true },
            React.createElement(Button, { onClick: logout, className: classes.logoutBtn, disabled: loading, sx: { padding: "0.65rem 1rem" } },
                React.createElement(Typography, { variant: "caption" }, "Sign out")))));
};
