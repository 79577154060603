import React, { useState } from "react";
import Grid from "@carrier/fds-react/Grid";
import Button from "@carrier/fds-react/Button";
import Typography from "@carrier/fds-react/Typography";
import { FormModal } from "../../components/NotificatioForm/FormModal";
import { getDefaultFormValues } from "../../utils/rules";
import { useTypedSelector } from "@/utils";
export var SubHeader = function () {
    var tenantId = useTypedSelector(function (state) { var _a, _b; return ((_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.tenant) === null || _b === void 0 ? void 0 : _b.id) || ""; });
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    return (React.createElement(Grid, { container: true, sx: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            height: "50px",
            padding: "9px 24px",
        }, justifyContent: "flex-end", alignItems: "center" },
        React.createElement(Button, { onClick: function () { return setOpenModal(true); } },
            React.createElement(Typography, { variant: "subtitle2" }, "Add Notification")),
        React.createElement(FormModal, { open: openModal, onClose: function () { return setOpenModal(false); }, initialValues: getDefaultFormValues(tenantId) })));
};
