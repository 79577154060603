var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
export var Nest = function (_a) {
    var components = _a.components, children = _a.children;
    return (React.createElement(React.Fragment, null, components.reverse().reduce(function (prev, curr) {
        var _a = Array.isArray(curr)
            ? [curr[0], curr[1]]
            : [curr, {}], Component = _a[0], props = _a[1];
        return React.createElement(Component, __assign({}, props), prev);
    }, children)));
};
