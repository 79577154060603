import React from "react";
import styles from "./styles.module.scss";
import { PolygonIcon } from "@/features/notifications/components/icons/PolygonIcon";
import { ReadonlyDropdown } from "@/features/notifications/components/NotificatioForm/Dropdown/ReadonlyDropdown";
import { useTypedSelector } from "@/utils";
export var GeofenceEventView = function (_a) {
    var expression = _a.expression;
    var _b = useTypedSelector(function (state) { return state.geofences; }), entities = _b.entities, loading = _b.loading;
    var items = (entities === null || entities === void 0 ? void 0 : entities.filter(function (geofence) { return expression.geofences.includes(geofence.geofenceId); }).map(function (geofence) { return ({
        value: geofence.geofenceId,
        label: geofence.name,
    }); })) || [];
    return (React.createElement("div", { className: styles.textBlock },
        React.createElement(PolygonIcon, { className: styles.icon }),
        React.createElement("div", null, "If"),
        React.createElement("div", { className: styles.condition },
            React.createElement("b", null, expression.comparison)),
        React.createElement("div", null, "any of the geofence"),
        React.createElement(ReadonlyDropdown, { items: items, selectValue: "(".concat(items.length, ") Geofences selected)"), isLoading: loading })));
};
