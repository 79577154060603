// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vgX_3L4tnIlMLhtEHgOd {\n  padding: 2px 15px;\n  margin: 0 0 20px 0;\n  font-size: 17px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.vgX_3L4tnIlMLhtEHgOd:hover {\n  background-color: #00000014;\n}\n.vgX_3L4tnIlMLhtEHgOd:hover .xjZFM1s7NA3AHbZSiCo5 {\n  visibility: hidden;\n}", "",{"version":3,"sources":["webpack://./src/features/notifications/components/NotificatioForm/Event/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,2BAAA;AACJ;AACI;EACE,kBAAA;AACN","sourcesContent":[".eventManager {\n  padding: 2px 15px;\n  margin: 0 0 20px 0;\n  font-size: 17px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &:hover {\n    background-color: #00000014;\n\n    .actions {\n      visibility: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventManager": "vgX_3L4tnIlMLhtEHgOd",
	"actions": "xjZFM1s7NA3AHbZSiCo5"
};
export default ___CSS_LOADER_EXPORT___;
