var _a, _b;
import { ColorsPaletteEnum, TextColorEnum, } from "@/theme/types/colors";
export var bootstrapColors = (_a = {},
    _a[ColorsPaletteEnum.Primary] = {
        main: "#270ADC",
        dark: "#1B079C",
        light: "#DEDBF0",
        contrast: "#fff",
        containedHoverBackground: "#270ADC",
        outlinedHoverBackground: "#eeebfc",
        outlinedRestingBackground: "#bab1f4",
    },
    _a[ColorsPaletteEnum.Secondary] = {
        main: "#424242",
        dark: "#212121",
        light: "#E0E0E0",
        contrast: "#fff",
        containedHoverBackground: "#b3b3b3",
        outlinedHoverBackground: "#ebebeb",
        outlinedRestingBackground: "#adadad",
    },
    _a[ColorsPaletteEnum.Error] = {
        main: "#F44336",
        dark: "#E31B0C",
        light: "#F88078",
        contrast: "#fff",
        containedHoverBackground: "#ab2f27",
        outlinedHoverBackground: "#fef0ef",
        outlinedRestingBackground: "#faa19b",
        content: "#621b16",
        background: "#feeceb",
    },
    _a[ColorsPaletteEnum.Info] = {
        main: "#2196F3",
        dark: "#0B79D0",
        light: "#64B6F7",
        contrast: "#fff",
        containedHoverBackground: "#1769aa",
        outlinedHoverBackground: "#edf7fe",
        outlinedRestingBackground: "#90caf9",
        content: "#0d3c61",
        background: "#e9f5fe",
    },
    _a[ColorsPaletteEnum.Warning] = {
        main: "#FFC107",
        dark: "#FFA000",
        light: "#FFD54F",
        contrast: "#fff",
        containedHoverBackground: "#b48704",
        outlinedHoverBackground: "#fefaeb",
        outlinedRestingBackground: "#fde083",
        content: "#664d03",
        background: "#fef9e6",
    },
    _a[ColorsPaletteEnum.Success] = {
        main: "#4CAF50",
        dark: "#3B873E",
        light: "#7BC67E",
        contrast: "#fff",
        containedHoverBackground: "#357b38",
        outlinedHoverBackground: "#f1f9f1",
        outlinedRestingBackground: "#a5d7a7",
        content: "#1e4620",
        background: "#edf7ed",
    },
    _a);
export var additionColors = {
    deliver: "#e0e0e0",
    outlinedBorder: "#c4c4c4",
    standardInputLine: "#949494",
    filledInputBackground: "#f5f5f5",
    backdropOverlay: "#808080",
    ratingActive: "#26C6DA",
    snackbarBackground: "#1a237f",
};
export var textColors = (_b = {},
    _b[TextColorEnum.Primary] = "#000",
    _b[TextColorEnum.Secondary] = "#666666",
    _b[TextColorEnum.Disabled] = "#9e9e9e",
    _b);
export var actionColors = {
    active: "#757575",
    hover: "#f5f5f5",
    selected: "#ebebeb",
    disabled: "#bdbdbd",
    disabledBackground: "#f5f5f5",
    focus: "#f5f5f5",
};
export var backgroundColors = {
    default: "#fafafa",
    paper: "#fff",
};
export var commonColors = {
    black: "#000",
    white: "#fff",
};
