import React from "react";
import { useLocation } from "react-router-dom";
// FDS
import Container from "@carrier/fds-react/Container";
import Grid from "@carrier/fds-react/Grid";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { PageLoader } from "@/components/PageLoader/PageLoader";
import { UserSettings } from "@/features/user-profile/components";
import { CompanyDialogue } from "@/features/company-management/components/companyDialogue";
import { AdvancedTrackingButton } from "@/features/advanced-tracking/components";
import { ApiPortalButton } from "@/features/api-portal/components";
import { useTypedSelector } from "@/utils";
import { navigation } from "@/index";
import "./styles.scss";
export var Header = function () {
    var location = useLocation();
    var breadcrumbsItems = [];
    var hasAdvancedTrackingButton = window.carrierExternalLinks.includes("advanced-tracking");
    var hasApiPortalButton = window.carrierExternalLinks.includes("api-portal");
    var userSettings = useTypedSelector(function (state) { return state.userSettings.data; });
    ////////////////
    // Breadcrumbs
    ////////////////
    // Loop through app's routes, re-arrange them to descend alphabetically by path
    navigation.slice(0).sort(function (n1, n2) {
        if (n1.path < n2.path) {
            return 1;
        }
        else if (n1.path > n2.path) {
            return -1;
        }
        return 0;
    }).forEach(function (n) {
        // If the current location is found in the array of routes, take its label and prepend to the list of breadcrumbs
        // Alternatively, if there are any child routes, prepend them to the list of breadcrumbs as well
        if (n.path === location.pathname || n.path.startsWith(location.pathname + "/")) {
            breadcrumbsItems.unshift({ pathname: n.path, title: n.label });
        }
    });
    return (React.createElement(Container, { sx: { px: 1, py: 1 }, maxWidth: false, className: "header" },
        React.createElement(PageLoader, { className: "header__progress-bar" }),
        React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "center" },
            React.createElement(Grid, null,
                React.createElement(Breadcrumbs, { items: breadcrumbsItems })),
            hasAdvancedTrackingButton && (
            // TODO remove the feature from bundle when it's turned off
            React.createElement(Grid, null,
                React.createElement(AdvancedTrackingButton, { language: userSettings === null || userSettings === void 0 ? void 0 : userSettings.language }))),
            hasApiPortalButton && (
            // TODO remove the feature from bundle when it's turned off
            React.createElement(Grid, null,
                React.createElement(ApiPortalButton, null))),
            React.createElement(Grid, null,
                React.createElement(UserSettings, null),
                React.createElement(CompanyDialogue, null)))));
};
