import React from "react";
import { NotificationsTable } from "./NotificationsTable";
import { Logo } from "@/components/Logo/Logo";
import { Navigation } from "@/components/Navigator/Navigation";
import { PageDrawerEvent } from "@/events";
import { MainPageContent } from "@/components/PageContent";
import { Header } from "@/components/Header";
import { SubHeader } from "@/features/notifications/components/NotificatioForm/SubHeader";
export function NotificationsPage(_a) {
    var BasicPageLayout = _a.layouts.BasicPageLayout, navigation = _a.navigation;
    return (React.createElement(BasicPageLayout, { header: React.createElement(React.Fragment, null,
            React.createElement(Header, null),
            React.createElement(SubHeader, null)), aside: React.createElement(React.Fragment, null,
            React.createElement(Logo, null),
            React.createElement(Navigation, { items: navigation, onClick: PageDrawerEvent.toggle })), content: React.createElement(MainPageContent, null,
            React.createElement(NotificationsTable, null)) }));
}
