var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState, } from "react";
import { useDispatch } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@carrier/fds-react/Button";
import Typography from "@carrier/fds-react/Typography";
import Drawer from "@carrier/fds-react/Drawer";
import TreeView from "@carrier/fds-react/TreeView";
import TreeItem from "@carrier/fds-react/TreeItem";
import Grid from "@carrier/fds-react/Grid";
import Skeleton from "@carrier/fds-react/Skeleton";
import { DrawerHeader } from "./DrawerHeader";
import { useDialogueTitle } from "@/features/company-management/hooks/useDialogueTitle";
import { fetchCompanies, selectActiveCompanyIds, selectAllCompanies, setSelectedCompanyIds, } from "@/features/company-management/stores/companiesSlice";
import { useTypedSelector } from "@/utils";
export var CompanyDialogue = function () {
    var dispatch = useDispatch();
    var _a = useState(false), openDrawer = _a[0], setOpenDrawer = _a[1];
    var _b = useState([]), roots = _b[0], setRoots = _b[1];
    var _c = useState([]), expandedIds = _c[0], setExpandedIds = _c[1];
    var _d = useState(new Map()), tenantMap = _d[0], setMap = _d[1];
    var _e = useState(""), searchText = _e[0], setSearchText = _e[1];
    var updateDrawerState = useCallback(function () {
        setOpenDrawer(function (prevValue) { return !prevValue; });
    }, []);
    var isLoading = useTypedSelector(function (state) { return state.companies.pending; });
    var allCompanies = useTypedSelector(selectAllCompanies);
    var selectedIds = useTypedSelector(selectActiveCompanyIds);
    var title = useDialogueTitle();
    var findParents = function (parents, node) {
        if (!node) {
            return;
        }
        parents.push(node);
        if (node.parentId) {
            findParents(parents, allCompanies.find(function (x) { return x.id === node.parentId; }));
        }
    };
    useEffect(function () {
        var tempRoot = [];
        var tempMap = new Map();
        var filteredTenants = allCompanies;
        var filteredSet = new Set();
        if (searchText) {
            allCompanies.forEach(function (x) {
                if (x.name && x.name.toLowerCase().includes(searchText.toLowerCase())) {
                    filteredSet.add(x);
                    var parents = [];
                    findParents(parents, x);
                    parents.forEach(function (p) { return filteredSet.add(p); });
                }
            });
            filteredTenants = Array.from(filteredSet);
        }
        filteredTenants.forEach(function (tenant) {
            if (!tenant.parentId) {
                tempRoot.push(tenant.id);
            }
            tempMap.set(tenant.id, {
                id: tenant.id,
                name: tenant.name,
                children: [],
            });
        });
        filteredTenants.forEach(function (tenant) {
            if (tenant.parentId && tempMap.has(tenant.parentId)) {
                tempMap.get(tenant.parentId).children.push(tenant.id);
            }
        });
        setRoots(tempRoot);
        setMap(tempMap);
    }, [allCompanies, searchText]);
    useEffect(function () {
        if (openDrawer) {
            dispatch(fetchCompanies());
        }
    }, [openDrawer]);
    var handleCompanySelect = function (event, nodeIds) {
        dispatch(setSelectedCompanyIds(nodeIds));
    };
    var handleCompanyToggle = function (event, nodeIds) {
        dispatch(setSelectedCompanyIds(__spreadArray(__spreadArray([], selectedIds, true), nodeIds, true)));
        setExpandedIds(nodeIds);
    };
    var renderTree = function (rootId) {
        if (!rootId) {
            return null;
        }
        var node = tenantMap.get(rootId);
        return (React.createElement(TreeItem, { key: rootId, nodeId: rootId, label: node.name }, node.children && Array.isArray(node.children) && node.children.length
            ? node.children.map(function (id) { return renderTree(id); })
            : null));
    };
    var handleSearch = function (event) {
        var newSearchText = event.target.value || "";
        setSearchText(newSearchText);
    };
    var handleClose = function () {
        setOpenDrawer(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: updateDrawerState, variant: "text", color: "secondary" },
            React.createElement(Typography, { variant: "subtitle2" }, title),
            React.createElement(MenuOpenIcon, null)),
        React.createElement(Drawer, { anchor: "right", open: openDrawer, onBackdropClick: updateDrawerState, PaperProps: {
                sx: { width: 375, padding: "20px" },
            } }, isLoading ? (React.createElement(React.Fragment, null,
            React.createElement(Skeleton, { animation: "pulse", width: 300, height: 30 }),
            React.createElement(Skeleton, { animation: "pulse", width: 300, height: 30 }),
            React.createElement(Skeleton, { animation: "pulse", width: 300, height: 30 }),
            React.createElement(Skeleton, { animation: "pulse", width: 300, height: 30 }))) : (React.createElement(Grid, { container: true, direction: "column", rowSpacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(DrawerHeader, { searchText: searchText, onSearch: handleSearch, onClose: handleClose })),
            React.createElement(Grid, { item: true },
                React.createElement(TreeView, { multiSelect: true, onNodeToggle: handleCompanyToggle, onNodeSelect: handleCompanySelect, selected: selectedIds, expanded: expandedIds, defaultCollapseIcon: React.createElement(ExpandMoreIcon, null), defaultExpandIcon: React.createElement(ChevronRightIcon, null) }, roots.map(function (rootId) { return renderTree(rootId); }))))))));
};
