import { LoginCallback } from "@okta/okta-react";
import { LoginPage } from "../components";
export var routes = [
    {
        path: "/auth/login/callback",
        exact: true,
        component: LoginCallback,
    },
    {
        path: "/auth/login",
        exact: true,
        component: LoginPage,
    },
    // {
    //   path: "/auth/logout",
    //   exact: true,
    //   component: LogoutPage,
    //   // TODO: this should really be a method and not a link
    //   navigationItem: {
    //     label: "Logout",
    //     icon: null, // TODO
    //     weight: 100000,
    //   },
    // },
];
