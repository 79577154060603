import React from "react";
import { TemperatureDeviationDialog } from "../../NotificatioForm/Event/Modals/TemperatureDeviation";
import { DoorDialog } from "../../NotificatioForm/Event/Modals/Door";
import { GeofenceDialog } from "../../NotificatioForm/Event/Modals/Geofence";
import { AlarmDialog } from "../../NotificatioForm/Event/Modals/Alarm";
export var EventModal = function (_a) {
    var modal = _a.modal, handleCancel = _a.handleCancel, handleOk = _a.handleOk, initialValue = _a.initialValue;
    return (React.createElement(React.Fragment, null,
        React.createElement(TemperatureDeviationDialog, { open: modal === "TEMPERATURE_DEVIATION", initialValue: initialValue, handleCancel: handleCancel, handleOk: handleOk }),
        React.createElement(DoorDialog, { open: modal === "DOOR", initialValue: initialValue, handleCancel: handleCancel, handleOk: handleOk }),
        React.createElement(GeofenceDialog, { open: modal === "GEOFENCE", initialValue: initialValue, handleCancel: handleCancel, handleOk: handleOk }),
        React.createElement(AlarmDialog, { open: modal === "TRU_ALARM", initialValue: initialValue, handleCancel: handleCancel, handleOk: handleOk })));
};
