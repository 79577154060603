import React from "react";
// TODO: Add import from @carrier/fds-react https://carrier-digital.atlassian.net/browse/CARRIERIO-973
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import FleetThemeProvider from "@carrier/fds-react/FleetThemeProvider";
import { fleetThemeOptions } from "@carrier/fds-react/FleetThemeOptions";
import { ThemeConfig } from "@/theme";
var theme = createTheme(fleetThemeOptions, ThemeConfig);
export var ThemeProvider = function (_a) {
    var children = _a.children;
    return (React.createElement(StyledEngineProvider, { injectFirst: true },
        React.createElement(FleetThemeProvider, { theme: theme }, children)));
};
