import { useCallback, useState } from "react";
export function useEventModal() {
    var _a = useState(null), currentModal = _a[0], setCurrentModal = _a[1];
    var handleCloseModal = useCallback(function () {
        setCurrentModal(null);
    }, []);
    var handleOpenModal = useCallback(function (newModal) {
        setCurrentModal(newModal);
    }, []);
    return {
        currentModal: currentModal,
        handleOpenModal: handleOpenModal,
        handleCloseModal: handleCloseModal,
    };
}
