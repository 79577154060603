import { createSlice } from "@reduxjs/toolkit";
export var slice = createSlice({
    name: "root",
    initialState: {
        extLoading: false,
    },
    reducers: {
        setExtLoading: function (state, action) {
            state.extLoading = action.payload;
        },
    },
});
export var setExtLoading = slice.actions.setExtLoading;
export var selectExtLoading = function (state) {
    return state.root.extLoading;
};
export var rootReducer = slice.reducer;
