import axios from "axios";
export var LOGIN_URL = "api/auth/login";
export var REGISTER_URL = "api/auth/register";
export var REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export var ME_URL = "api/me";
export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}
export function login(email, password) {
    return axios.post(LOGIN_URL, { email: email, password: password });
}
export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, { email: email, fullname: fullname, username: username, password: password });
}
export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, { email: email });
}
