import React from "react";
import Tooltip from "@carrier/fds-react/Tooltip";
import Fade from "@carrier/fds-react/Fade";
import Button from "@carrier/fds-react/Button";
import ApiPortalIcon from "../assets/api-portal-icon.svg";
import { useTooltipStyles } from "@/utils";
export var ApiPortalButton = function () {
    var tooltipClasses = useTooltipStyles();
    var onClick = function () {
        var apiPortalWindow = window.open(process.env.REACT_APP_API_PORTAL || "", "_blank");
        apiPortalWindow === null || apiPortalWindow === void 0 ? void 0 : apiPortalWindow.focus();
    };
    return (React.createElement(Tooltip, { title: "API Portal", classes: tooltipClasses, placement: "bottom", arrow: true, enterDelay: 800, TransitionComponent: Fade },
        React.createElement(Button, { variant: "text", color: "secondary", onClick: onClick },
            React.createElement("img", { src: ApiPortalIcon, alt: "API Portal" }))));
};
