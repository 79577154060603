import { makeStyles } from "@mui/styles";
export var useTooltipStyles = makeStyles(function () { return ({
    arrow: {
        // TODO the color is incorrect from the FDS theme
        // color: theme.palette.primary.main,
        color: "#270ADC",
    },
    tooltip: {
        // TODO the backgroundColor is incorrect from the FDS theme
        // backgroundColor: theme.palette.info.main,
        backgroundColor: "#270ADC",
        fontSize: "14px",
        padding: "8px",
        color: "#fff",
        WebkitFilter: "initial",
    },
    tooltipPlacementRight: {
        "& .MuiTooltip-arrow": {
            marginLeft: "-0.71em !important",
        },
    },
}); });
