import React, { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@carrier/fds-react/Button";
import Menu from "@carrier/fds-react/Menu";
import MenuItem from "@carrier/fds-react/MenuItem";
import ListItemIcon from "@carrier/fds-react/ListItemIcon";
import Typography from "@carrier/fds-react/Typography";
import { EventModal } from "../..//NotificatioForm/Event/EventModal";
import { useEventModal } from "@/features/notifications/hooks/useEventModal";
import { NOTIFICATION_EVENT_MENU_ITEMS } from "@/features/notifications/constants";
export var AddEventButton = function (_a) {
    var onAdd = _a.onAdd, events = _a.events;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = useEventModal(), currentModal = _c.currentModal, handleCloseModal = _c.handleCloseModal, handleOpenModal = _c.handleOpenModal;
    var handleClick = useCallback(function (event) {
        setAnchorEl(event.currentTarget);
    }, []);
    var handleClose = useCallback(function () {
        setAnchorEl(null);
    }, []);
    var handleEventItemClick = useCallback(function (newModal) {
        handleClose();
        handleOpenModal(newModal);
    }, [handleClose, handleOpenModal]);
    var handleEventCreate = useCallback(function (currentEvent) {
        onAdd(currentEvent);
        handleCloseModal();
    }, [handleCloseModal, onAdd]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", onClick: handleClick, sx: { margin: "16px 0 24px" }, color: "secondary" },
            React.createElement(AddIcon, null),
            React.createElement(Typography, { variant: "subtitle1" },
                React.createElement("strong", null, "Add Event"))),
        React.createElement(Menu, { id: "add-event-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose }, events.map(function (event) {
            var _a = NOTIFICATION_EVENT_MENU_ITEMS[event], Icon = _a.icon, title = _a.title;
            return (React.createElement(MenuItem, { onClick: function () { return handleEventItemClick(event); }, key: event },
                React.createElement(ListItemIcon, null,
                    React.createElement(Icon, { key: null, type: undefined, props: undefined })),
                React.createElement(Typography, { variant: "subtitle1" }, title)));
        })),
        React.createElement(EventModal, { modal: currentModal, handleCancel: handleCloseModal, handleOk: handleEventCreate })));
};
