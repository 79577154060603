// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fKVNBWYVOl4f_18R9GBm {\n  margin: 0 12px;\n}\n.fKVNBWYVOl4f_18R9GBm:before, .fKVNBWYVOl4f_18R9GBm:after {\n  border: none;\n}\n.fKVNBWYVOl4f_18R9GBm:hover:not(.ZdKQDYi0rzl_CkhVnlQz):before {\n  border: none !important;\n}\n.fKVNBWYVOl4f_18R9GBm:focus:not(.OydUaxA2TCSedB1IEdjW):before {\n  border: none !important;\n}\n\n.cVT9VzaXpjtSOPFlaaW1 {\n  padding-right: 20px;\n  font-weight: 600;\n  color: #270adc;\n  font-size: 18px;\n  line-height: 24px;\n  text-transform: lowercase;\n}\n\n.FptteCy1IHSO1h97P24X {\n  fill: #270adc;\n}", "",{"version":3,"sources":["webpack://./src/features/notifications/components/NotificatioForm/Dropdown/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EAEE,YAAA;AAAJ;AAGE;EACE,uBAAA;AADJ;AAGE;EACE,uBAAA;AADJ;;AAKA;EACE,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAFF;;AAKA;EACE,aAAA;AAFF","sourcesContent":[".selectContainer {\n  margin: 0 12px;\n\n  &:before,\n  &:after {\n    border: none;\n  }\n\n  &:hover:not(.Mui-disabled):before {\n    border: none !important;\n  }\n  &:focus:not(.Mui-focused):before {\n    border: none !important;\n  }\n}\n\n.selectRoot {\n  padding-right: 20px;\n  font-weight: 600;\n  color: #270adc;\n  font-size: 18px;\n  line-height: 24px;\n  text-transform: lowercase;\n}\n\n.selectIcon {\n  fill: #270adc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectContainer": "fKVNBWYVOl4f_18R9GBm",
	"Mui-disabled": "ZdKQDYi0rzl_CkhVnlQz",
	"Mui-focused": "OydUaxA2TCSedB1IEdjW",
	"selectRoot": "cVT9VzaXpjtSOPFlaaW1",
	"selectIcon": "FptteCy1IHSO1h97P24X"
};
export default ___CSS_LOADER_EXPORT___;
