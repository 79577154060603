import { useCallback, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
export var useLogout = function () {
    var oktaAuth = useOktaAuth().oktaAuth;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), error = _b[0], setError = _b[1];
    var logout = useCallback(function () {
        setLoading(true);
        oktaAuth
            .signOut()
            .catch(function () {
            setError(true);
        })
            .finally(function () { return setLoading(false); });
    }, [oktaAuth]);
    return {
        logout: logout,
        loading: loading,
        error: error,
    };
};
