export var DEFAULT_COLUMN_VALUE = {
    columns: [],
    name: "",
    __typename: "",
};
export var SELECT_MENU_POSITION = {
    anchorOrigin: {
        vertical: "top",
        horizontal: "center",
    },
};
export var DEFAULT_USER_SETTINGS = {
    language: null,
    measurementSpeed: null,
    measurementTemperature: null,
    timezone: null,
    notificationEnabled: false,
    measurementVolume: null,
    measurementDistance: null,
    assetListColumns: [DEFAULT_COLUMN_VALUE],
    restApiEnabled: false,
};
