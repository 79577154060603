import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import LinearProgress from "@carrier/fds-react/LinearProgress";
import { useLocation } from "react-router-dom";
var timoutId;
export var PageLoader = function (_a) {
    var className = _a.className, _b = _a.speed, speed = _b === void 0 ? 100 : _b;
    var location = useLocation();
    var _c = useState(1), value = _c[0], setValue = _c[1];
    var prevValue = useRef(value);
    prevValue.current = value;
    var stopAnimate = function () {
        clearTimeout(timoutId);
        setValue(0);
    };
    var animate = function () {
        if (prevValue.current <= 100) {
            timoutId = setTimeout(function () {
                setValue(prevValue.current + 10);
                animate();
            }, speed);
        }
        else {
            stopAnimate();
        }
    };
    useEffect(function () {
        animate();
        return function () {
            clearTimeout(timoutId);
        };
    }, [location.pathname]);
    return (React.createElement("div", { className: clsx("progress-bar", className) }, value > 0 && (React.createElement(LinearProgress, { color: "primary", value: value, valueBuffer: 0, variant: "determinate" }))));
};
