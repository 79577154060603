import React, { useState } from "react";
//import { Link } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
// eslint-disable-next-line
import { makeStyles } from "@mui/styles";
import Button from "@carrier/fds-react/Button";
import Input from "@carrier/fds-react/Input";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import Alert from "@carrier/fds-react/Alert";
import { LynxLogo } from "@/assets/images/LynxLogo";
import LoginImage from "@/assets/images/login.jpg";
import { LOGIN_FAIL } from "@/features/authentication/constants/messages";
var useStyles = makeStyles({
    mainContainer: {
        width: "100%",
        height: "100vh",
        padding: 0,
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "row",
    },
    lynxLogo: {
        height: "80px",
        width: "277px",
    },
    loginContainer: {
        minHeight: "100%",
    },
    loginTextinput: {
        border: 1,
        borderColor: "#000000",
        fontSize: 30,
    },
    loginForm: {
        display: "block",
        marginTop: "auto",
        padding: 1,
        paddingLeft: 0,
        paddingRight: 0,
        "& .MuiOutlinedInput-input": {
            paddingTop: 2,
            paddingBottom: 2,
        },
        "& .Mui-error": {
            color: "#cc0000",
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#cc0000",
            },
        },
        "& .MuiFormHelperText-root": {
            color: "#cc0000",
        },
    },
});
export var LoginPage = function () {
    var oktaAuth = useOktaAuth().oktaAuth;
    var _a = useState(""), email = _a[0], setEmail = _a[1];
    var _b = useState(""), password = _b[0], setPassword = _b[1];
    var _c = useState(false), isError = _c[0], setIsError = _c[1];
    var handleEmailChange = function (event) {
        setEmail(event.target.value);
    };
    var handlePasswordChange = function (event) {
        setPassword(event.target.value);
    };
    var handleClick = function (event) {
        event.preventDefault();
        oktaAuth
            .signInWithCredentials({ username: email, password: password })
            .then(function (res) {
            setIsError(false);
            // setSessionToken(res.sessionToken);
            // Note: sessionToken is a one-use token, so make sure this is only called once
            oktaAuth.signInWithRedirect({ sessionToken: res.sessionToken });
        })
            .catch(function (err) {
            console.error(err);
            setIsError(true);
        });
    };
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, flexDirection: "row", justifyContent: "center", alignItems: "center", style: {
            background: "#fff",
            width: "100%",
            height: "100vh",
            margin: 0,
            padding: 0,
        } },
        React.createElement(Grid, { container: true, item: true, flexDirection: "column", xs: 8, justifyContent: "space-around", alignContent: "center", textAlign: "center", alignSelf: "center", style: {} },
            React.createElement(Grid, { container: true, item: true, flexDirection: "row", xs: 6, style: { background: "#faa" } },
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff" } },
                    React.createElement(LynxLogo, { style: { width: 222, height: 64 }, className: classes.lynxLogo })),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 50 } },
                    React.createElement(Typography, { align: "left", variant: "h4", style: { color: "#000", lineHeight: 2 } }, "Welcome to LYNX Fleet")),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 50 } },
                    React.createElement(Typography, { align: "left", style: { color: "#000", lineHeight: 3 } }, "Login with your username and password"),
                    isError && (React.createElement(Alert, { role: "alert", severity: "error", variant: "filled" }, LOGIN_FAIL))),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 50 } },
                    React.createElement(Input, { placeholder: "email", showBorder: true, size: "xsmall", value: email, onChange: handleEmailChange })),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 50 } },
                    React.createElement(Input, { placeholder: "password", showBorder: true, size: "xsmall", type: "password", value: password, onChange: handlePasswordChange })),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 50 } },
                    React.createElement(Button, { onClick: handleClick }, "Submit")),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 30 } },
                    React.createElement(Typography, { align: "left", style: { color: "#000", lineHeight: 3, fontSize: 12 } }, "By continuing you agree to our Terms of Service")),
                React.createElement(Grid, { container: true, flexDirection: "column", style: { background: "#fff", height: 30 } },
                    React.createElement(Typography, { align: "left", style: { color: "#000", lineHeight: 3, fontSize: 12 } }, "\u00A9 2022 Carrier")))),
        React.createElement(Grid, { container: true, item: true, xs: 4, flexDirection: "column", justifyContent: "space-around", alignContent: "center", textAlign: "center", alignSelf: "center", style: { background: "#00ff00", height: "100vh" } },
            React.createElement("img", { src: LoginImage, style: { objectFit: "cover", width: "100%", height: "100vh" }, alt: "Lynx logo" }))));
};
