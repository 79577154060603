import React, { useState } from "react";
// TODO: import Select from FDS when task https://carrier-digital.atlassian.net/browse/CARRIERIO-1320 will be implemented
import { Select } from "@material-ui/core";
import CircularProgress from "@carrier/fds-react/CircularProgress";
import MenuItem from "@carrier/fds-react/MenuItem";
import styles from "./styles.module.scss";
export var ReadonlyDropdown = function (_a) {
    var items = _a.items, selectValue = _a.selectValue, isLoading = _a.isLoading;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    return !isLoading ? (React.createElement(Select, { open: open, displayEmpty: true, onClose: handleClose, onOpen: handleOpen, className: styles.selectContainer, classes: { root: styles.selectRoot, icon: styles.selectIcon }, value: "" },
        React.createElement(MenuItem, { disabled: true, value: "" }, selectValue),
        items
            .sort(function (a, b) { return a.label.localeCompare(b.label); })
            .map(function (item) { return (React.createElement(MenuItem, { disabled: true, value: item.value, key: item.value }, item.label)); }))) : (React.createElement(CircularProgress, { style: { marginLeft: 10 }, size: 18, color: "primary" }));
};
