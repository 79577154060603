import React from "react";
import styles from "./styles.module.scss";
import { DoorIcon } from "@/features/notifications/components/icons/DoorIcon";
export var DoorEventView = function (_a) {
    var expression = _a.expression;
    return (React.createElement("div", { className: styles.textBlock },
        React.createElement(DoorIcon, { className: styles.icon }),
        React.createElement("div", null, "if door is"),
        React.createElement("div", { className: styles.condition },
            React.createElement("b", null, expression.comparison))));
};
