import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import { Aside } from "@/components/Aside";
import { Header } from "@/components/Header";
import { ExtLoading } from "@/components/ExtLoading/ExtLoading";
import { fetchUserSettings } from "@/features/user-profile/stores/userSettingsSlice";
import { fetchUserData } from "@/features/authentication/stores/authSlice";
import { useTypedSelector } from "@/utils";
import { selectExtLoading } from "@/stores/root";
import { styles } from "./styles";
var LayoutStyled = styled("div")(styles.layout);
var MainStyled = styled("main")(styles.main);
var ContentStyled = styled("section")(styles.content);
export function BasicPageLayout(_a) {
    var header = _a.header, aside = _a.aside, content = _a.content, footer = _a.footer;
    var authState = useOktaAuth().authState;
    var dispatch = useDispatch();
    var isExtLoading = useTypedSelector(selectExtLoading);
    useEffect(function () {
        var _a, _b, _c, _d;
        // TODO move it from Layout
        var email = ((_b = (_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.email) ||
            ((_d = (_c = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _c === void 0 ? void 0 : _c.claims) === null || _d === void 0 ? void 0 : _d.sub) ||
            "";
        dispatch(fetchUserSettings(email));
        dispatch(fetchUserData(email));
    }, []);
    return (React.createElement(LayoutStyled, null,
        isExtLoading && React.createElement(ExtLoading, { text: "Loading\u2026" }),
        aside && React.createElement(Aside, null, aside),
        React.createElement(MainStyled, null,
            (header && React.createElement(Header, null, header)) || React.createElement(Header, null),
            React.createElement(ContentStyled, null, content && React.createElement(React.Fragment, null, content)),
            footer && React.createElement("footer", null, footer))));
}
