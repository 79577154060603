import { eventEmitter } from "@/utils/eventEmitter";
export var PAGE_DRAWER_EVENT = "page-drawer-event";
var toggle = function () {
    eventEmitter.emit(PAGE_DRAWER_EVENT);
};
var subscribe = function (callback) {
    eventEmitter.on(PAGE_DRAWER_EVENT, function () { return callback && callback(); });
};
var unsubscribe = function () {
    eventEmitter.off(PAGE_DRAWER_EVENT);
};
export var PageDrawerEvent = {
    toggle: toggle,
    subscribe: subscribe,
    unsubscribe: unsubscribe,
};
