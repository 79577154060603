import React, { useMemo } from "react";
import { RbacProvider, createPermissionsRequest, } from "@carrier-io/rbac-provider-react";
import { useOktaAuth } from "@okta/okta-react";
var enableRbac = process.env.REACT_APP_RBAC_FEATURE_ENABLED === "true";
var rbacUrl = process.env.REACT_APP_USER_CONTEXT_URL;
export var AuthorizationProvider = function (_a) {
    var _b, _c;
    var children = _a.children;
    var _d = (_c = (_b = useOktaAuth()) === null || _b === void 0 ? void 0 : _b.authState) !== null && _c !== void 0 ? _c : {}, accessToken = _d.accessToken, idToken = _d.idToken;
    var request = useMemo(function () {
        var authenticationToken = accessToken === null || accessToken === void 0 ? void 0 : accessToken.accessToken;
        return createPermissionsRequest({
            url: rbacUrl,
            authenticationToken: authenticationToken,
            authenticationHeader: "Authorization",
        });
    }, [accessToken, idToken]);
    return (React.createElement(RbacProvider, { permissionsRequest: request, enabled: enableRbac }, children));
};
