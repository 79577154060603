import convert from "convert-units";
function format(val) {
    return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        useGrouping: false,
    }).format(val);
}
export function toCelsius(tempVal) {
    return Number(format(convert(Number(tempVal)).from("F").to("C")));
}
export function toFahrenheit(tempVal) {
    return Number(format(convert(Number(tempVal)).from("C").to("F")));
}
export function toUnit(tempVal, tempUnit) {
    return Number(tempUnit === "C" ? tempVal : toFahrenheit(tempVal));
}
export function toFahrenheitDelta(tempVal) {
    return Number(format((Number(tempVal) * 9) / 5));
}
export function toCelsiusDelta(tempVal) {
    return Number(format((Number(tempVal) * 5) / 9));
}
