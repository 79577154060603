var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloLink } from "@apollo/client";
import * as LogRocket from "logrocket";
var LOGROCKET_NOT_INITIALIZED_ERROR_MSG = "Must call LogRocket.init() before sessionURL becomes available.";
export var logrocketMiddleware = new ApolloLink(function (operation, forward) {
    var sessionURL = LogRocket.sessionURL;
    if (sessionURL && sessionURL !== LOGROCKET_NOT_INITIALIZED_ERROR_MSG) {
        // add the LogRocket session url to the headers
        operation.setContext(function (_a) {
            var _b = _a.headers, headers = _b === void 0 ? {} : _b;
            return ({
                headers: __assign(__assign({}, headers), { "X-LogRocket-URL": sessionURL }),
            });
        });
    }
    return forward(operation);
});
