import React from "react";
import Grid from "@carrier/fds-react/Grid";
import { FilterExport } from "@/features/assets/components/InteractiveMap/FilterExport";
import { FilterLayers } from "@/features/assets/components/InteractiveMap/FilterLayers";
import { FilterSearch } from "@/features/assets/components/InteractiveMap/FilterSearch";
export var FilterPanel = function () {
    return (React.createElement(Grid, { container: true, direction: "row", justifyContent: "space-between" },
        React.createElement(Grid, { item: true },
            React.createElement(FilterSearch, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, direction: "row", justifyContent: "flex-end" },
                React.createElement(FilterLayers, null),
                React.createElement(FilterExport, null)))));
};
