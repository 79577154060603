import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
// MUI
import { makeStyles } from "@mui/styles";
// FDS
import Button from "@carrier/fds-react/Button";
import Tooltip from "@carrier/fds-react/Tooltip";
import Box from "@carrier/fds-react/Box";
import Fade from "@carrier/fds-react/Fade";
// TODO: get rid of it, calculate automatically
import { MENU_BUTTON_HEIGHT } from "@/constants";
import { useTooltipStyles } from "@/utils";
var useStyles = makeStyles(function () { return ({
    menuContainer: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        position: "relative",
        width: "100%",
    },
    menuTopSection: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "center",
        position: "relative",
    },
    menuButtonSelected: {
        "& .MuiButton-label": {
            height: "24px",
            "& .MuiSvgIcon-root": {
                height: "24px",
                width: "24px",
            },
        },
        width: "100%",
        paddingTop: "8px",
        borderRadius: "0px",
        height: "60px",
        color: "#270ADC",
    },
    menuButton: {
        "& .MuiButton-label": {
            height: "24px",
            "& .MuiSvgIcon-root": {
                height: "24px",
                width: "24px",
            },
        },
        width: "100%",
        paddingTop: "8px",
        borderRadius: "0px",
        height: "60px",
    },
    menuSelectionRule: {
        position: "absolute",
        backgroundColor: "#270ADC",
        width: "5px",
        height: "".concat(MENU_BUTTON_HEIGHT, "px"),
        top: function (_a) {
            var ruleTopVal = _a.ruleTopVal;
            return "".concat(ruleTopVal, "px");
        },
        transition: "top .5s ease-out, opacity .5s linear",
    },
}); });
export var Navigation = function (_a) {
    var items = _a.items, onClick = _a.onClick;
    var history = useHistory();
    var tooltipClasses = useTooltipStyles();
    var currentPathname = history.location.pathname;
    var _b = useState(items[0]), selectedNavigationItem = _b[0], setSelectedNavigationItem = _b[1];
    var ruleTopVal = useMemo(function () { return MENU_BUTTON_HEIGHT * items.indexOf(selectedNavigationItem); }, [items, selectedNavigationItem]);
    var classes = useStyles({ ruleTopVal: ruleTopVal });
    useEffect(function () {
        var currentNavigationItem = items.find(function (_a) {
            var path = _a.path;
            return path === currentPathname;
        });
        setSelectedNavigationItem(currentNavigationItem || items[0]);
    }, [items, currentPathname]);
    var handleClick = function (path) {
        if (path !== currentPathname) {
            history.push(path);
            return;
        }
        if (onClick) {
            onClick();
        }
    };
    return (React.createElement(Box, { className: classes.menuContainer },
        React.createElement(Box, { className: classes.menuSelectionRule }),
        React.createElement(Box, { className: classes.menuTopSection }, items.map(function (navigationItem) { return (React.createElement(Tooltip, { key: navigationItem.key, title: navigationItem.label, classes: tooltipClasses, placement: "right", arrow: true, enterDelay: 800, TransitionComponent: Fade },
            React.createElement(Button, { variant: "text", color: "secondary", className: selectedNavigationItem.key === navigationItem.key
                    ? classes.menuButtonSelected
                    : classes.menuButton, onClick: function () { return handleClick(navigationItem.path); } }, navigationItem.icon))); }))));
};
