export var getDefaultFormValues = function (tenantId) { return ({
    name: "",
    enableTimeCondition: false,
    sendEmail: true,
    time: { hr: 0, min: 0 },
    conditions: [],
    exceptConditions: [],
    recipients: [],
    assets: {
        companyId: tenantId,
        allAssets: true,
    },
}); };
export var isDoorEvent = function (event) {
    return event.type === "DOOR";
};
export var isTemperatureDeviationEvent = function (event) {
    return event.type === "TEMPERATURE_DEVIATION";
};
export var isGeofenceEvent = function (event) {
    return event.type === "GEOFENCE";
};
export var isTruAlarmEvent = function (event) {
    return event.type === "TRU_ALARM";
};
