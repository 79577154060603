import React, { useCallback, useState } from "react";
import Box from "@carrier/fds-react/Box";
import Modal from "@carrier/fds-react/Modal";
import { ModalHeader } from "@/features/notifications/components/NotificatioForm/ModalHeader";
import { Steps } from "@/features/notifications/components/NotificatioForm/Steps";
import { bootstrapColors } from "@/theme/constants";
export var FormModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, initialValues = _a.initialValues;
    var _b = useState(0), currentStep = _b[0], setCurrentStep = _b[1];
    var getStyles = function () {
        var paddingSize = "64px";
        return {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: bootstrapColors.Primary.contrast,
            width: "calc(100% - ".concat(paddingSize, ")"),
            maxWidth: "960px",
            maxHeight: "calc(100% - ".concat(paddingSize, ")"),
            borderRadius: "4px",
        };
    };
    var updateStep = useCallback(function (step) {
        setCurrentStep(step);
    }, [setCurrentStep]);
    return (React.createElement(Modal, { onClose: onClose, open: open },
        React.createElement(Box, { sx: getStyles() },
            React.createElement(ModalHeader, { onClose: onClose, currentStep: currentStep }),
            React.createElement(Steps, { currentStep: currentStep, updateStep: updateStep, initialValues: initialValues, onClose: onClose }))));
};
