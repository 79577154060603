export var styles = {
    aside: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "75px",
        height: "100vh",
        backgroundColor: "#f0f2f9",
        zIndex: 9,
    },
};
