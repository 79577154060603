import { fonts } from "@/theme/constants";
var fontFamily = fonts.Gibson;
export var themeTypography = {
    fontFamily: fontFamily,
    h1: {
        fontFamily: fontFamily,
    },
    h2: {
        fontFamily: fontFamily,
    },
    h3: {
        fontFamily: fontFamily,
    },
    h4: {
        fontFamily: fontFamily,
    },
    h5: {
        fontFamily: fontFamily,
    },
    h6: {
        fontFamily: fontFamily,
    },
    subtitle1: {
        fontFamily: fontFamily,
    },
    subtitle2: {
        fontFamily: fontFamily,
    },
    body1: {
        fontFamily: fontFamily,
    },
    body2: {
        fontFamily: fontFamily,
    },
    overline: {
        fontFamily: fontFamily,
    },
    caption: {
        fontFamily: fontFamily,
    },
};
