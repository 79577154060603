var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Okta
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
var oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    scopes: ["openid", "email", "profile", "groups"],
    pkce: true,
    redirectUri: "".concat(window.location.origin, "/auth/login/callback"),
});
export var AuthenticationProvider = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var _b = useState(false), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var customAuthHandler = function () {
        // Redirect to the login page
        // history.push('/auth/login'); // XXX TODO FIXME
        if (window.location.pathname !== "/auth/login") {
            window.location.href = "/auth/login";
        }
    };
    var restoreOriginalUri = useCallback(function (_oktaAuth, originalUri) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // history.replace(toRelativeUrl(originalUri || '/', window.location.origin)); // XXX TODO FIXME
            window.location.href = toRelativeUrl(originalUri || "/", window.location.origin);
            return [2 /*return*/];
        });
    }); }, [history]);
    var authStateChanged = useCallback(function (authState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsAuthenticated(authState.isAuthenticated);
            return [2 /*return*/];
        });
    }); }, []);
    useEffect(function () {
        var authStateManager = oktaAuth.authStateManager;
        authStateManager.subscribe(authStateChanged);
        return function () { return authStateManager.unsubscribe(authStateChanged); };
    }, [authStateChanged]);
    if (isAuthenticated && window.location.pathname === "/auth/login") {
        // history.push('/assets'); // XXX TODO FIXME
        window.location.href = "/";
    }
    return (React.createElement(Security, { oktaAuth: oktaAuth, onAuthRequired: customAuthHandler, restoreOriginalUri: restoreOriginalUri }, children));
};
