export var Z_INDEXES = {
    modal: 9000,
    dropdown: 7000,
    header: 6000,
    footer: 5000,
    drawer: 1500,
    drawerBg: 1400,
    toggles: 1300,
    navigation: 1000,
    drawerMenu: 9,
    hidden: -1,
};
