var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import Autocomplete from "@carrier/fds-react/Autocomplete";
import TextField from "@carrier/fds-react/TextField";
import { getRecipients } from "@/features/notifications/api";
export var RecipientSelector = function (_a) {
    var value = _a.value, onChange = _a.onChange, rest = __rest(_a, ["value", "onChange"]);
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState([]), recipients = _c[0], setRecipients = _c[1];
    useEffect(function () {
        // TODO: move it to redux and redux-thunk
        getRecipients()
            .then(function (_a) {
            var _b, _c, _d;
            var data = _a.data;
            var pageRecipients = data.pageRecipients;
            if (pageRecipients.success) {
                setRecipients((_c = (_b = pageRecipients.docs) === null || _b === void 0 ? void 0 : _b.map(function (pageRecipient) { return pageRecipient.email; })) !== null && _c !== void 0 ? _c : []);
            }
            else {
                throw new Error((_d = pageRecipients.error) !== null && _d !== void 0 ? _d : "Error");
            }
            setLoading(false);
        })
            .catch(function (error) {
            setLoading(false);
            throw error;
        });
    }, []);
    return (React.createElement(Autocomplete, __assign({ multiple: true, limitTags: 1, options: recipients, getOptionLabel: function (option) { return option; }, value: value, onChange: onChange, loading: loading, size: "medium", renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { label: "Select recipients", helperText: "Email notifications will be sent from: ".concat(process.env.REACT_APP_NOTIFICATION_SENDER_EMAIL || "") }))); } }, rest)));
};
