import React from "react";
export function DialogPageLayout(_a) {
    var header = _a.header, logo = _a.logo, nav = _a.nav, content = _a.content, footer = _a.footer;
    return (React.createElement(React.Fragment, null,
        header && React.createElement("header", null, header),
        (logo || nav) && (React.createElement("nav", null,
            logo,
            nav)),
        content && React.createElement("section", null, content),
        footer && React.createElement("footer", null, footer)));
}
