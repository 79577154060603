var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@/providers/ThemeProvider";
import { AuthorizationProvider } from "@/features/authentication/providers";
import { store } from "@/stores";
import { Routes } from "@/routes";
import { Nest, parsePackageJsonFeatures, checkAuthenticationCapabilities, getRoutesWithNavItem, getNavigation, } from "@/utils";
import { DialogPageLayout } from "@/components/layouts/DialogPageLayout";
import { BasicPageLayout } from "@/components/layouts/BasicPageLayout";
import { ApplicationProvider } from "@/providers/ApplicationContext";
import { getUserClient } from "@/utils/apollo/apolloClients";
import "@/styles/index.scss";
// Parse package.json and determine which features to load
var _a = parsePackageJsonFeatures(), carrierFeatures = _a.carrierFeatures, featureModules = _a.featureModules;
// Determine if any of the feature-modules provide authentication capabilities
var authenticationCapabilitiesEnabled = checkAuthenticationCapabilities(featureModules);
window.carrierFeatures = carrierFeatures;
///////////////
// Navigation
///////////////
var _b = getRoutesWithNavItem({
    featureModules: featureModules,
    carrierFeatures: carrierFeatures,
}), routesWithNavItems = _b.routesWithNavItems, externalLink = _b.externalLink;
window.carrierExternalLinks = externalLink;
export var navigation = getNavigation(routesWithNavItems);
// Global layouts for this app
var layouts = {
    BasicPageLayout: BasicPageLayout,
    DialogPageLayout: DialogPageLayout,
};
var reducers = {};
var routes = [];
var providers = [];
// Parse feature-modules // TODO create separate function for that into engine.tsx
featureModules.forEach(function (fm, fi) {
    var featureModuleName = carrierFeatures[fi];
    if ("providers" in fm) {
        for (var providerName in fm.providers) {
            providers.push(fm.providers[providerName]);
        }
    }
    if ("routes" in fm) {
        fm.routes.forEach(function (fmRoute) {
            var thisRouteIsProtected = authenticationCapabilitiesEnabled &&
                fmRoute.treatAsProtectedWhenAuthenticationIsAvailable;
            if ("redirect" in fmRoute && fmRoute.redirect === true) {
                // This is a redirection route
                var fmRedirectRoute_1 = (React.createElement(Redirect, { from: fmRoute.from, to: fmRoute.to }));
                if (thisRouteIsProtected) {
                    routes.push(React.createElement(SecureRoute, { path: "/", component: function () { return fmRedirectRoute_1; } }));
                }
                else {
                    routes.push(fmRedirectRoute_1);
                }
            }
            else if (fmRoute.path && fmRoute.component) {
                // This is a conventional route
                var fmPathRoute_1 = (React.createElement(Route, { path: fmRoute.path, component: function () { return React.createElement(fmRoute.component, __assign({}, { layouts: layouts, navigation: navigation })); } }));
                if (thisRouteIsProtected) {
                    routes.push(React.createElement(SecureRoute, { path: "/", component: function () { return fmPathRoute_1; } }));
                }
                else {
                    routes.push(fmPathRoute_1);
                }
            }
        });
    }
    if ("slice" in fm) {
        reducers[featureModuleName] = fm.slice.reducer;
    }
});
//////////////////////////
// Global Redux provider
//////////////////////////
providers.push([Provider, { store: store }]);
providers.push([AuthorizationProvider]);
///////////////////
// Render the app
///////////////////
ReactDOM.render(React.createElement(ApplicationProvider, null,
    React.createElement(ApolloProvider, { client: getUserClient() },
        React.createElement(ThemeProvider, null,
            React.createElement(Nest, { components: providers },
                React.createElement(Routes, null,
                    React.createElement(React.Fragment, null, routes.map(function (route, i) { return React.cloneElement(route, { key: i }); }))))))), document.getElementById("app"));
