import React, { useState } from "react";
import Tab from "@carrier/fds-react/Tab";
import TabPanel from "@carrier/fds-react/TabPanel";
import Tabs from "@carrier/fds-react/Tabs";
import TabContext from "@carrier/fds-react/TabContext";
import { additionColors } from "@/theme/constants";
export var TabbedContainer = function () {
    var _a = useState("1"), value = _a[0], setValue = _a[1];
    var handleChange = function (_event, newValue) {
        setValue(newValue);
    };
    return (React.createElement(TabContext, { value: value },
        React.createElement(Tabs, { value: value, onChange: handleChange, sx: { backgroundColor: additionColors.filledInputBackground } },
            React.createElement(Tab, { label: "Assets(0)", value: "1" }),
            React.createElement(Tab, { label: "Geofences(0)", value: "2" })),
        React.createElement(TabPanel, { value: "1", sx: { padding: 0 } }, "Assets grid will be here ..."),
        React.createElement(TabPanel, { value: "2", sx: { padding: 0 } }, "Geofences grid will be here ...")));
};
