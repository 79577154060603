import { selectActiveCompanyIds, selectAllCompanies, } from "@/features/company-management/stores/companiesSlice";
import { useTypedSelector } from "@/utils";
export var useDialogueTitle = function () {
    var companyIds = useTypedSelector(selectActiveCompanyIds);
    var allCompanies = useTypedSelector(selectAllCompanies);
    if (companyIds.length > 1) {
        return "Multiple";
    }
    if (companyIds.length === 1) {
        var currentCompany = allCompanies.find(function (company) { return company.id === companyIds[0]; });
        return currentCompany === null || currentCompany === void 0 ? void 0 : currentCompany.name;
    }
    return "All Companies";
};
