import React from "react";
import Switch from "@carrier/fds-react/Switch";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
export var Notifications = function (_a) {
    var Name = _a.Name, userSettings = _a.userSettings, handleSettingChange = _a.handleSettingChange;
    return (React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "center", sx: { marginBottom: "1.5rem" } },
        React.createElement(Grid, null,
            React.createElement(Typography, { variant: "body2" },
                React.createElement("strong", null, Name))),
        React.createElement(Grid, null,
            React.createElement(Switch, { name: "notificationEnabled", size: "small", checked: !!userSettings.notificationEnabled, onChange: function (event) {
                    handleSettingChange({
                        target: {
                            name: "notificationEnabled",
                            value: event.target.checked,
                        },
                    });
                } }))));
};
