import React from "react";
import { Logo } from "@/components/Logo/Logo";
import { Navigation } from "@/components/Navigator/Navigation";
import { PageDrawerEvent } from "@/events";
import { MainPageContent } from "@/components/PageContent";
export function AdvancedCompanyManagementPage(_a) {
    var BasicPageLayout = _a.layouts.BasicPageLayout, navigation = _a.navigation;
    return (React.createElement(BasicPageLayout, { aside: React.createElement(React.Fragment, null,
            React.createElement(Logo, null),
            React.createElement(Navigation, { items: navigation, onClick: PageDrawerEvent.toggle })), content: React.createElement(MainPageContent, null,
            React.createElement("div", null, "Advanced company management page in develop")) }));
}
