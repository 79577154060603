var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
// eslint-disable-next-line
import SvgIcon from "@mui/material/SvgIcon";
export var LynxLogo = function (props) { return (React.createElement(SvgIcon, __assign({}, props, { fill: "none", viewBox: "0 0 222 64", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M199.504 0H218.666L200.611 26.5598L191.061 12.421L199.504 0ZM163.682 0.000226394H158.666L179.085 30.0385L155.999 63.9998H161L183.904 30.0957L163.682 0.000226394ZM191.085 47.6917L202.171 64H221.333L200.647 33.5701L191.085 47.6917Z", fill: "#152C73" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M100 0H116L140 36V0H156V60V64H140L116 28V64H100V4V0Z", fill: "#152C73" }),
        React.createElement("path", { d: "M15.9999 50.5726V0H0V63.9998H36.1081L45.3332 50.5726H15.9999Z", fill: "#152C73" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M99.9996 0H80.0225L67.4931 17.6797L76.4038 33.295L99.9996 0ZM73.5938 37.2601H58.6665L56.414 33.3129L34.6665 63.9998H54.6437L73.5938 37.2601Z", fill: "#152C73" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M52.304 0H32.3005L53.5663 37.191H53.7045L34.6665 64H54.6472L73.6333 37.2641H73.5235L73.482 37.191H73.5698L52.304 0Z", fill: "#0093E5" }),
        React.createElement("path", { d: "M177.821 0H158.694L179.064 30.0957L155.993 64H175.173L198.298 30.0957L177.821 0Z", fill: "#0093E5" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "221.333", height: "64", fill: "white" }))))); };
