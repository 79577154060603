import React from "react";
import { useFormikContext } from "formik";
import Button from "@carrier/fds-react/Button";
import Grid from "@carrier/fds-react/Grid";
import { STEP_LABELS } from "@/features/notifications/constants";
export var StepFooter = function (_a) {
    var updateStep = _a.updateStep, currentStep = _a.currentStep, submitting = _a.submitting;
    var handleSubmit = useFormikContext().handleSubmit;
    var isLastStep = currentStep === STEP_LABELS.length - 1;
    return (React.createElement(Grid, { sx: { marginTop: "8px" } },
        React.createElement(Button, { disabled: !currentStep, onClick: function () { return updateStep(currentStep - 1); }, variant: "outlined", color: "secondary", sx: { marginRight: "8px" } },
            React.createElement("b", null, "Back")),
        isLastStep ? (React.createElement(Button, { disabled: submitting, onClick: function () { return handleSubmit(); } },
            React.createElement("b", null, "Save"))) : (React.createElement(Button, { onClick: function () { return updateStep(currentStep + 1); } },
            React.createElement("b", null, "Next")))));
};
