import React from "react";
import { Link, Popover, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
var useStyles = makeStyles(function () {
    return createStyles({
        closeButton: {
            cursor: "pointer",
            position: "absolute",
            right: 8,
            top: 8,
        },
        topRowText: {
            paddingRight: 24,
        },
        text: {
            fontSize: 12,
            marginBottom: 16,
        },
        lowercaseText: {
            textTransform: "lowercase",
        },
        paper: {
            padding: "20px",
        },
        boldText: {
            fontWeight: "bold",
        },
        active: {
            backgroundColor: "#DEDBF0",
            color: "#1B079C",
        },
        label: {
            fontSize: 14,
        },
        listItem: {
            marginLeft: "12px",
        },
        link: {
            display: "block",
        },
    });
});
export function RulesFormatter() {
    // const { t } = useTranslation();
    var t = function (x) { return x; };
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var classes = useStyles();
    // const time = parseTimeString(secondsToTime(value?.onlySendWhenConditionMetSeconds));
    var handleClick = function (event) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? "simple-popover" : undefined;
    // useEffect(() => {
    //   dispatch(fetchGeofences());
    // }, [dispatch]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { "aria-describedby": id, href: "#", onClick: handleClick, className: classes.link }, t("View" /*'common.view'*/)),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "left",
            } },
            React.createElement("div", { className: classes.paper },
                React.createElement(CloseIcon, { "aria-label": t("common.close"), className: classes.closeButton, onClick: handleClose })))));
}
