var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import isEqual from "lodash/isEqual";
import { makeStyles } from "@mui/styles";
import { FormControl, Select } from "@mui/material"; // TODO have to move from @fds (https://carrier-digital.atlassian.net/browse/CARRIERIO-1105)
import Button from "@carrier/fds-react/Button";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import MenuItem from "@carrier/fds-react/MenuItem";
import Box from "@carrier/fds-react/Box";
import { useDispatch } from "react-redux";
import { saveUserSettings } from "../../api/index";
import { SELECT_MENU_POSITION } from "../../constants";
import { Measurement } from "@/features/user-profile/components/settings/Measurement";
import { Notifications } from "@/features/user-profile/components/settings/Notifications";
import { useTypedSelector } from "@/utils";
import { updateSettings } from "@/features/user-profile/stores/userSettingsSlice";
import { getUpdatedSettings } from "@/features/user-profile/utils";
import { TypographyEnum } from "@/theme/types";
var useStyles = makeStyles(function () {
    // TODO: remove this property when will add global fontSize value
    var fontSize = "13px!important";
    return {
        selector: {
            marginTop: "0.5rem",
            fontSize: fontSize,
        },
        options: {
            "& ul > li": {
                fontSize: fontSize,
            },
        },
    };
});
export var SettingsForm = function (_a) {
    var snackbarState = _a.snackbarState, setSnackbarState = _a.setSnackbarState;
    var dispatch = useDispatch();
    var userSettings = useTypedSelector(function (state) { return state.userSettings.data; });
    var _b = useState(userSettings), formUserSettings = _b[0], setFormUserSettings = _b[1];
    var authState = useOktaAuth().authState;
    var classes = useStyles();
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var handleSettingChange = function (event) {
        var _a;
        var _b = event.target, _c = _b.name, name = _c === void 0 ? "" : _c, _d = _b.value, value = _d === void 0 ? "" : _d;
        setFormUserSettings(__assign(__assign({}, formUserSettings), (_a = {}, _a[name] = value, _a)));
    };
    var resetSettings = function () {
        setFormUserSettings(__assign({}, userSettings));
    };
    var changedFormFields = useMemo(function () {
        return isEqual(userSettings, formUserSettings);
    }, [userSettings, formUserSettings]);
    var saveSettings = function (event) {
        event.preventDefault();
        if (!authState ||
            !authState.isAuthenticated ||
            !authState.idToken ||
            !authState.idToken.claims.email) {
            return;
        }
        setLoading(true);
        var updatedSettings = getUpdatedSettings(authState.idToken.claims.email, formUserSettings);
        saveUserSettings(updatedSettings)
            .then(function () {
            setSnackbarState(__assign(__assign({}, snackbarState), { open: true, message: "Settings saved successfully" }));
            dispatch(updateSettings(updatedSettings));
        })
            .catch(function () {
            setSnackbarState(__assign(__assign({}, snackbarState), { open: true, message: "Failed to save. Try again." }));
        })
            .finally(function () { return setLoading(false); });
    };
    useEffect(function () {
        setFormUserSettings(userSettings);
    }, [userSettings]);
    return (React.createElement(Grid, { container: true, sx: { marginTop: "1.75rem" } },
        React.createElement("form", { style: { width: "100%" }, onSubmit: saveSettings },
            React.createElement(FormControl, { fullWidth: true, variant: "standard" },
                React.createElement(Typography, { variant: "subtitle2" }, "Default Language"),
                React.createElement(Select, { displayEmpty: true, id: "language-select", onChange: handleSettingChange, value: formUserSettings.language || "", name: "language", className: classes.selector, MenuProps: __assign({ classes: { paper: classes.options } }, SELECT_MENU_POSITION) },
                    React.createElement(MenuItem, { value: "en-US" }, "English"),
                    React.createElement(MenuItem, { value: "es-ES" }, "Spanish"),
                    React.createElement(MenuItem, { value: "fr-FR" }, "French"),
                    React.createElement(MenuItem, { value: "nl-NL" }, "Dutch"),
                    React.createElement(MenuItem, { value: "de-DE" }, "German"))),
            React.createElement(FormControl, { fullWidth: true, sx: { marginTop: "3rem" }, variant: "standard" },
                React.createElement(Typography, { variant: "subtitle2" }, "Default Timezone"),
                React.createElement(Select, { displayEmpty: true, id: "timezone-select", onChange: handleSettingChange, value: formUserSettings.timezone || "GMT+2:00", name: "timezone", className: classes.selector, MenuProps: __assign({ classes: { paper: classes.options } }, SELECT_MENU_POSITION) },
                    React.createElement(MenuItem, { value: "GMT+2:00" }, "Central European (GMT+2:00)"))),
            React.createElement(Box, { sx: { marginTop: "3rem", marginBottom: "0.75rem" } },
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("strong", null, "Measurement"))),
            React.createElement(Measurement, { Name: "Temperature", MeasurementName: "measurementTemperature", Metric: { Name: "Celsius", Value: "C" }, Imperial: { Name: "Fahrenheit", Value: "F" }, value: formUserSettings.measurementTemperature || "", handleSettingChange: handleSettingChange }),
            React.createElement(Measurement, { Name: "Distance", MeasurementName: "measurementDistance", Metric: { Name: "Kilometers", Value: "KM" }, Imperial: { Name: "Miles", Value: "MI" }, value: formUserSettings.measurementDistance || "", handleSettingChange: handleSettingChange }),
            React.createElement(Measurement, { Name: "Volume", MeasurementName: "measurementVolume", Metric: { Name: "Litres", Value: "L" }, Imperial: { Name: "Gallons", Value: "G" }, value: formUserSettings.measurementVolume || "", handleSettingChange: handleSettingChange }),
            React.createElement(Measurement, { Name: "Speed", MeasurementName: "measurementSpeed", Metric: { Name: "KPH", Value: "KPH" }, Imperial: { Name: "MPH", Value: "MPH" }, value: formUserSettings.measurementSpeed || "", handleSettingChange: handleSettingChange }),
            process.env.REACT_APP_NOTIFICATIONS_ENABLED === "true" && (React.createElement(Notifications, { Name: "Notifications", userSettings: formUserSettings, handleSettingChange: handleSettingChange })),
            React.createElement(FormControl, { fullWidth: true },
                React.createElement(Grid, { container: true, justifyContent: "flex-end" },
                    React.createElement(Button, { onClick: resetSettings, sx: { marginRight: "0.75rem" }, color: "inherit", disabled: changedFormFields, size: "large" },
                        React.createElement(Typography, { variant: "caption", variantMapping: { caption: TypographyEnum.XSLabel } },
                            React.createElement("strong", null, "Cancel"))),
                    React.createElement(Button, { type: "submit", disabled: loading, size: "large" },
                        React.createElement(Typography, { variant: "caption", variantMapping: { caption: TypographyEnum.XSLabel } },
                            React.createElement("strong", null, "Save"))))))));
};
