export var UserGroups;
(function (UserGroups) {
    UserGroups["CarrierAdmin"] = "Carrier_Celsius_Admin";
    UserGroups["CarrierTechnician"] = "Carrier_Celsius_Technician";
    UserGroups["FleetAdmin"] = "Carrier_Celsius_Fleet_Admin";
    UserGroups["Everyone"] = "Everyone";
    UserGroups["CommandAdmin"] = "Carrier_Celsius_Command_Admin";
    UserGroups["APIPortalAdmin"] = "Carrier_Celsius_API_Portal_Admin";
})(UserGroups || (UserGroups = {}));
var getGroups = function (authState) {
    var _a, _b;
    if ((_b = (_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.groups) {
        return authState.idToken.claims.groups;
    }
    return [];
};
export var inAnyGroup = function (authState, groups) {
    var authGroups = getGroups(authState);
    return groups.some(function (group) { return authGroups.includes(group); });
};
export var inAllGroups = function (authState, groups) {
    var authGroups = getGroups(authState);
    return groups.every(function (group) { return authGroups.includes(group); });
};
export var isCarrierAdmin = function (authState) {
    return inAllGroups(authState, [UserGroups.CarrierAdmin]);
};
export var isTechnician = function (authState) {
    return inAllGroups(authState, [UserGroups.CarrierTechnician]);
};
export var isFleetAdmin = function (authState) {
    return inAllGroups(authState, [UserGroups.FleetAdmin]);
};
export var isAPIPortalAdmin = function (authState) {
    return inAllGroups(authState, [UserGroups.APIPortalAdmin]);
};
