import React from "react";
import { styled } from "@mui/system";
import { actionColors, typography } from "@/theme/constants";
var ListStyled = styled("ul")({
    paddingLeft: 16,
    margin: 0,
    "& li": {
        color: actionColors.disabled,
        fontSize: typography.Caption.fontSize,
    },
});
export var ExtraRules = function () {
    return (React.createElement(ListStyled, null,
        React.createElement("li", null, "will not send if TRU is off"),
        React.createElement("li", null, "will not send if unit is defrosting")));
};
