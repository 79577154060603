var _a;
import { createSlice } from "@reduxjs/toolkit";
export var slice = createSlice({
    name: "assets",
    initialState: {
        count: 0,
    },
    reducers: {
        increment: function (state) {
            state.count += 1;
        },
        decrement: function (state) {
            state.count -= 1;
        },
    },
});
export var increment = (_a = slice.actions, _a.increment), decrement = _a.decrement;
export var selectCount = function (state) {
    return state.assets.count;
};
export var assetsReducer = slice.reducer;
