var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from "@reduxjs/toolkit";
export var slice = createSlice({
    name: "notifications",
    initialState: {
        isLoading: false,
        entities: null,
        error: null,
    },
    reducers: {
        catchError: function (state, action) { return (__assign(__assign({}, state), { error: "".concat(action.type, ": ").concat(action.payload.error), isLoading: false })); },
        startCall: function (state) { return (__assign(__assign({}, state), { error: null, isLoading: true })); },
        notificationsFetched: function (state, action) {
            var entities = action.payload.entities;
            return __assign(__assign({}, state), { isLoading: false, error: null, entities: entities });
        },
        notificationCreated: function (state, action) {
            var notification = action.payload.notification;
            return __assign(__assign({}, state), { entities: __spreadArray(__spreadArray([], ((state === null || state === void 0 ? void 0 : state.entities) || []), true), [notification], false) });
        },
        notificationUpdated: function (state, action) {
            if (!(state === null || state === void 0 ? void 0 : state.entities)) {
                return state;
            }
            var notification = action.payload.notification;
            var notificationToUpdateIndex = state.entities.findIndex(function (item) { return item.notificationId === notification.notificationId; });
            if (notificationToUpdateIndex === -1) {
                return state;
            }
            return __assign(__assign({}, state), { entities: __spreadArray(__spreadArray(__spreadArray([], state.entities.slice(0, notificationToUpdateIndex), true), [
                    __assign(__assign({}, state.entities[notificationToUpdateIndex]), notification)
                ], false), state.entities.slice(notificationToUpdateIndex + 1), true) });
        },
        notificationDeleted: function (state, action) {
            if (!(state === null || state === void 0 ? void 0 : state.entities)) {
                return state;
            }
            var notificationId = action.payload.notificationId;
            return __assign(__assign({}, state), { entities: state.entities.filter(function (item) { return item.notificationId !== notificationId; }) });
        },
    },
});
export var notificationCreated = slice.actions.notificationCreated;
export var notificationsReducer = slice.reducer;
