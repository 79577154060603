import { CommandHistoryPage } from "../components";
import CommandHistoryIconAssetUrl from "../assets/command-history-icon.svg";
export var routes = [
    {
        path: "/command-history",
        exact: true,
        treatAsProtectedWhenAuthenticationIsAvailable: true,
        component: CommandHistoryPage,
        navigationItem: {
            label: "Command History",
            icon: CommandHistoryIconAssetUrl,
            weight: 50000,
            external: false,
        },
    },
];
