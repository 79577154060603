import React from "react";
import ToggleButton from "@carrier/fds-react/ToggleButton";
import ToggleButtonGroup from "@carrier/fds-react/ToggleButtonGroup";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import { makeStyles } from "@mui/styles";
import { bootstrapColors, textColors, typography } from "@/theme/constants";
import { TextColorEnum, TypographyEnum } from "@/theme/types";
var useStyles = makeStyles(function () { return ({
    toggleBtnGroup: {
        "& .MuiToggleButton-root": {
            fontSize: typography[TypographyEnum.XSLabel].fontSize,
            fontWeight: 700,
            color: textColors[TextColorEnum.Primary],
            padding: "2px 4px",
            borderColor: textColors[TextColorEnum.Primary],
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
            },
            "&.Mui-selected": {
                backgroundColor: bootstrapColors.Primary.main,
                color: bootstrapColors.Primary.contrast,
            },
        },
    },
}); });
export var Measurement = function (_a) {
    var MeasurementName = _a.MeasurementName, Name = _a.Name, Metric = _a.Metric, Imperial = _a.Imperial, value = _a.value, handleSettingChange = _a.handleSettingChange;
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "center", sx: { marginBottom: "0.75rem" } },
        React.createElement(Grid, null,
            React.createElement(Typography, { variant: "caption", variantMapping: { caption: TypographyEnum.XSLabel } },
                React.createElement("strong", null, Name))),
        React.createElement(Grid, null,
            React.createElement(ToggleButtonGroup, { exclusive: true, "aria-label": Name, value: value, onChange: function (e) { return handleSettingChange(e); }, size: "small", className: classes.toggleBtnGroup },
                React.createElement(ToggleButton, { name: MeasurementName, value: Imperial.Value }, Imperial.Name),
                React.createElement(ToggleButton, { name: MeasurementName, value: Metric.Value }, Metric.Name)))));
};
