import { bootstrapColors } from "@/theme/constants/colors";
import { ColorsPaletteEnum } from "@/theme/types/colors";
var primary = bootstrapColors[ColorsPaletteEnum.Primary];
var secondary = bootstrapColors[ColorsPaletteEnum.Secondary];
var error = bootstrapColors[ColorsPaletteEnum.Error];
var warning = bootstrapColors[ColorsPaletteEnum.Warning];
var info = bootstrapColors[ColorsPaletteEnum.Info];
var success = bootstrapColors[ColorsPaletteEnum.Success];
export var themePalette = {
    //Override settings for colors; e.g. primary, secondary
    primary: {
        light: primary.light,
        main: primary.main,
        dark: primary.dark,
        contrastText: primary.contrast,
    },
    secondary: {
        light: secondary.light,
        main: secondary.main,
        dark: secondary.dark,
        contrastText: secondary.contrast,
    },
    error: {
        light: error.light,
        main: error.main,
        dark: error.dark,
        contrastText: error.contrast,
    },
    warning: {
        light: warning.light,
        main: warning.main,
        dark: warning.dark,
        contrastText: warning.contrast,
    },
    info: {
        light: info.light,
        main: info.main,
        dark: info.dark,
        contrastText: info.contrast,
    },
    success: {
        light: success.light,
        main: success.main,
        dark: success.dark,
        contrastText: success.contrast,
    },
};
