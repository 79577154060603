import React from "react";
import Button from "@carrier/fds-react/Button";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import CloseIcon from "@mui/icons-material/Close";
export var DrawerHeader = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Grid, { container: true, direction: "row", justifyContent: "space-between", alignItems: "center", sx: { marginBottom: "1.25rem" } },
        React.createElement(Typography, { variant: "caption", variantMapping: { caption: "h6" } }, "User Profile"),
        React.createElement(Button, { variant: "text", color: "secondary", onClick: onClose, sx: { minWidth: "auto" }, size: "small" },
            React.createElement(CloseIcon, { style: { fontSize: "1.1rem" } }))));
};
