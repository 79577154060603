import clsx from "clsx";
import React from "react";
import { useHistory, Link } from "react-router-dom";
// MUI
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { makeStyles } from "@mui/styles";
var useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        textTransform: "capitalize",
        fontSize: "13px",
        fontWeight: 600,
        lineHeight: "24px",
        "&:hover": {
            // color will use from themeConfig
            color: "#270adc",
            textDecoration: "none",
        },
    },
});
export var BreadcrumbsBack = function (_a) {
    var path = _a.path, className = _a.className;
    var classes = useStyles();
    var history = useHistory();
    var handleClick = function (path) {
        if (path !== window.location.pathname) {
            history.push(path);
        }
    };
    return (React.createElement(Link, { to: "javascript:;", onClick: function () { return handleClick(path.pathname); }, className: clsx(classes.root, className) },
        React.createElement(ChevronLeftIcon, { fontSize: "small" }),
        path.title));
};
