// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ez2XiJqR7sb3ZfDElt24 {\n  font-size: 17px;\n  display: flex;\n  align-items: center;\n}\n\n._84sjIOZ3R2JcMtDXnMVO {\n  margin-bottom: 25px;\n}\n\n.E1D49DR4H4TwtnHnlZ9Y {\n  border-radius: 4px;\n  font-size: 17px;\n  min-height: 200px;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/features/notifications/components/NotificatioForm/Event/Modals/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;AAGF","sourcesContent":[".textBlock {\n  font-size: 17px;\n  display: flex;\n  align-items: center;\n}\n.geofenceRule {\n  margin-bottom: 25px;\n}\n.select {\n  border-radius: 4px;\n  font-size: 17px;\n  min-height: 200px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBlock": "ez2XiJqR7sb3ZfDElt24",
	"geofenceRule": "_84sjIOZ3R2JcMtDXnMVO",
	"select": "E1D49DR4H4TwtnHnlZ9Y"
};
export default ___CSS_LOADER_EXPORT___;
