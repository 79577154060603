export var ColorsPaletteEnum;
(function (ColorsPaletteEnum) {
    ColorsPaletteEnum["Primary"] = "Primary";
    ColorsPaletteEnum["Secondary"] = "Secondary";
    ColorsPaletteEnum["Success"] = "Success";
    ColorsPaletteEnum["Info"] = "Info";
    ColorsPaletteEnum["Warning"] = "Warning";
    ColorsPaletteEnum["Error"] = "Error";
})(ColorsPaletteEnum || (ColorsPaletteEnum = {}));
export var TextColorEnum;
(function (TextColorEnum) {
    TextColorEnum["Primary"] = "Primary";
    TextColorEnum["Secondary"] = "Secondary";
    TextColorEnum["Disabled"] = "Disabled";
})(TextColorEnum || (TextColorEnum = {}));
