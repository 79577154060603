var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useContext, useState, useCallback } from "react";
import isFunction from "lodash/isFunction";
import { initialState } from "@/constants/applicationContext";
var ApplicationContext = createContext({});
export function useApplicationContext() {
    return useContext(ApplicationContext);
}
// Establish functions for modifying the state of the application context
export function ApplicationProvider(_a) {
    var children = _a.children;
    var _b = useState(initialState), applicationState = _b[0], setApplicationStateBase = _b[1];
    // allows modification of all asset list parameters.
    // Function check is added to support metronic bootstrap table helper
    var setAssetListParameters = useCallback(function (newAssetListParams) {
        setApplicationStateBase(function (prevAppState) {
            var assetListParameters = __assign(__assign({}, prevAppState.assetListParameters), (isFunction(newAssetListParams)
                ? newAssetListParams(prevAppState.assetListParameters)
                : {}));
            return __assign(__assign({}, prevAppState), { assetListParameters: assetListParameters });
        });
    }, []);
    var setAssetListPageSize = useCallback(function (newPageSize) {
        setApplicationStateBase(function (prevAppState) {
            var newAssetListParameters = __assign(__assign({}, prevAppState.assetListParameters), { pageSize: newPageSize });
            return __assign(__assign({}, prevAppState), { assetListParameters: newAssetListParameters });
        });
    }, []);
    var setAssetListView = useCallback(function (newView) {
        setApplicationStateBase(function (prevAppState) {
            var newAssetListParameters = __assign(__assign({}, prevAppState.assetListParameters), { selectedView: newView });
            return __assign(__assign({}, prevAppState), { assetListParameters: newAssetListParameters });
        });
    }, []);
    var setDeviceManagementSettings = useCallback(function (deviceManagementSettings) {
        setApplicationStateBase(function (prevAppState) {
            var newDeviceManagementSettings = __assign(__assign({}, prevAppState.deviceManagementSettings), deviceManagementSettings);
            return __assign(__assign({}, prevAppState), { deviceManagementSettings: newDeviceManagementSettings });
        });
    }, []);
    var setProvisionedDeviceSelectedRow = useCallback(function (selectedRow) {
        setApplicationStateBase(function (prevAppState) {
            var newDeviceManagementSettings = __assign(__assign({}, prevAppState.deviceManagementSettings), { deviceProvisioningSelectedRow: __assign(__assign({}, initialState.deviceManagementSettings
                    .deviceProvisioningSelectedRow), selectedRow) });
            return __assign(__assign({}, prevAppState), { deviceManagementSettings: newDeviceManagementSettings });
        });
    }, []);
    var clearProvisionedDeviceSelectedRow = useCallback(function () {
        setApplicationStateBase(function (prevAppState) { return (__assign(__assign({}, prevAppState), { deviceManagementSettings: __assign(__assign({}, prevAppState.deviceManagementSettings), { deviceProvisioningSelectedRow: __assign({}, initialState.deviceManagementSettings
                    .deviceProvisioningSelectedRow) }) })); });
    }, []);
    var setCompanyManagementSettings = useCallback(function (companyManagementSettings) {
        setApplicationStateBase(function (prevAppState) { return (__assign(__assign({}, prevAppState), { companyManagementSettings: companyManagementSettings })); });
    }, []);
    var setAssetReportManagement = useCallback(function (assetReportManagement) {
        setApplicationStateBase(function (prevAppState) { return (__assign(__assign({}, prevAppState), { assetReportManagement: __assign(__assign({}, prevAppState.assetReportManagement), assetReportManagement) })); });
    }, []);
    var setLegendSettings = useCallback(function (legendSettings) {
        setApplicationStateBase(function (prevAppState) { return (__assign(__assign({}, prevAppState), { legendSettings: __assign(__assign(__assign({}, prevAppState.legendSettings), legendSettings), { events: __assign(__assign({}, prevAppState.legendSettings.events), legendSettings.events) }) })); });
    }, []);
    var setAssetListFilterSettings = useCallback(function (filterSettings) {
        setApplicationStateBase(function (prevAppState) {
            var _a;
            return (__assign(__assign({}, prevAppState), { assetListParameters: __assign(__assign({}, prevAppState.assetListParameters), { filter: __assign(__assign({}, (_a = prevAppState.assetListParameters) === null || _a === void 0 ? void 0 : _a.filter), filterSettings) }) }));
        });
    }, []);
    var setAssetHistoryChartSelectedView = useCallback(function (newView) {
        setApplicationStateBase(function (prevAppState) { return (__assign(__assign({}, prevAppState), { assetHistoryChartSelectedView: newView })); });
    }, []);
    var value = {
        applicationState: applicationState,
        setAssetListParameters: setAssetListParameters,
        setAssetListPageSize: setAssetListPageSize,
        setAssetListView: setAssetListView,
        setDeviceManagementSettings: setDeviceManagementSettings,
        setProvisionedDeviceSelectedRow: setProvisionedDeviceSelectedRow,
        clearProvisionedDeviceSelectedRow: clearProvisionedDeviceSelectedRow,
        setCompanyManagementSettings: setCompanyManagementSettings,
        setAssetReportManagement: setAssetReportManagement,
        setLegendSettings: setLegendSettings,
        setAssetListFilterSettings: setAssetListFilterSettings,
        setAssetHistoryChartSelectedView: setAssetHistoryChartSelectedView,
    };
    return (React.createElement(ApplicationContext.Provider, { value: value }, children));
}
