import React from "react";
import styles from "./styles.module.scss";
import { AlarmIcon } from "@/features/notifications/components/icons/AlarmIcon";
export var AlarmEventView = function (_a) {
    var expression = _a.expression;
    var comparison = expression.comparison.replace(/_/g, " ");
    return (React.createElement("div", { className: styles.textBlock },
        React.createElement(AlarmIcon, { className: styles.icon }),
        React.createElement("div", null, "If the alarm, "),
        React.createElement("div", { className: styles.condition },
            React.createElement("b", null, comparison)),
        React.createElement("div", null, "is active")));
};
