import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import Typography from "@carrier/fds-react/Typography";
import Box from "@carrier/fds-react/Box";
import { isDoorEvent, isGeofenceEvent, isTemperatureDeviationEvent, } from "../../../../utils";
import styles from "./styles.module.scss";
import { StepSubHeader } from "@/features/notifications/components/NotificatioForm/Steps/StepSubHeader";
import { DoorEventView } from "@/features/notifications/components/NotificatioForm/Event/EventViews/Door";
import { TemperatureDeviationEventView } from "@/features/notifications/components/NotificatioForm/Event/EventViews/TemperatureDeviation";
import { GeofenceEventView } from "@/features/notifications/components/NotificatioForm/Event/EventViews/Geofence";
import { ReadonlyDropdown } from "@/features/notifications/components/NotificatioForm/Dropdown/ReadonlyDropdown";
import { CompanyTreeItemType } from "@/types/company";
import { GET_ASSETS, } from "@/features/assets/api";
import { typography } from "@/theme/constants";
export var NotificationReviewStep = function () {
    var values = useFormikContext().values;
    var time = values.time;
    // Obtain list of assets based on company ID
    var _a = useQuery(GET_ASSETS, {
        variables: {
            for: {
                // @ts-ignore
                type: CompanyTreeItemType.COMPANY || null,
                id: values.assets.companyId,
            },
        },
    }), data = _a.data, loading = _a.loading;
    var assets = useMemo(function () {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.getAssets) === null || _a === void 0 ? void 0 : _a.items.map(function (asset) { return ({
            value: asset.name || "",
            label: asset.name || "",
        }); }).sort(function (a, b) { return a.label.localeCompare(b.label); })) || [];
    }, [data]);
    var recipients = values.recipients;
    var recipientItems = recipients
        .sort(function (a, b) { return a.localeCompare(b); })
        .map(function (recipient) { return ({
        value: recipient,
        label: recipient,
    }); }) || [];
    var recipientsDropdownValue = "(".concat(recipients.length, ") ").concat(recipients.length !== 1 ? "Recipients selected" : "Recipient selected");
    var assetsDropdownValue = "(".concat(assets === null || assets === void 0 ? void 0 : assets.length, ") ").concat((assets === null || assets === void 0 ? void 0 : assets.length) !== 1 ? "Assets selected" : "Asset selected");
    return (React.createElement(React.Fragment, null,
        React.createElement(StepSubHeader, { title: values.name || "Notification Name" }),
        React.createElement(Box, { sx: {
                fontSize: typography.h6.fontSize,
                padding: 0,
                margin: "16px 0",
            } },
            "Send a notification if\u00A0",
            React.createElement(Box, { sx: { fontSize: typography.h5.fontSize, display: "inline-block" } },
                React.createElement("strong", null, "all")),
            "\u00A0of the conditions are met:"),
        values.conditions.length > 0 ? (values.conditions.map(function (event) { return (React.createElement("div", { key: event.type + event.expression.comparison, className: "".concat(styles.text, " ").concat(styles.listItem) },
            isDoorEvent(event) && (React.createElement(DoorEventView, { expression: event.expression })),
            isTemperatureDeviationEvent(event) && (React.createElement(TemperatureDeviationEventView, { expression: event.expression })),
            isGeofenceEvent(event) && (React.createElement(GeofenceEventView, { expression: event.expression })))); })) : (React.createElement(Typography, { className: "".concat(styles.text, " ").concat(styles.listItem) }, "None")),
        values.conditions.length > 0 && values.conditions[0].type !== "DOOR" && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: styles.text },
                "Except when\u00A0",
                React.createElement("span", { className: "".concat(styles.boldText, " ").concat(styles.lowercaseText) }, "Any"),
                "\u00A0 of the conditions are met:"),
            values.exceptConditions.length > 0 ? (values.exceptConditions.map(function (event) { return (React.createElement("div", { key: event.type + event.expression.comparison, className: "".concat(styles.text, " ").concat(styles.listItem) }, isDoorEvent(event) && (React.createElement(DoorEventView, { expression: event.expression })))); })) : (React.createElement(Typography, { className: "".concat(styles.text, " ").concat(styles.listItem) }, "None")))),
        values.enableTimeCondition && (React.createElement(Typography, { className: styles.text },
            "Only send notification when the above conditions are met for\u00A0",
            React.createElement("span", { className: styles.boldText },
                time.hr,
                " ",
                time.hr !== 1 ? "hours" : "hour",
                " & ",
                time.min,
                "v",
                time.min !== 1 ? "minutes" : "minute"))),
        React.createElement(Typography, { className: styles.text },
            React.createElement("span", { className: styles.boldText },
                "When these assets affected by this rule:",
                " ",
                React.createElement(ReadonlyDropdown, { items: assets, selectValue: assetsDropdownValue, isLoading: loading }))),
        values.sendEmail && (React.createElement(Typography, { className: styles.text },
            React.createElement("b", null, "Send email to:\u00A0"),
            React.createElement(ReadonlyDropdown, { items: recipientItems, selectValue: recipientsDropdownValue })))));
};
