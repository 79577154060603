import "cross-fetch/polyfill";
import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";
import { authMiddleware } from "./authMiddleware";
import { logrocketMiddleware } from "./logRocketMiddleware";
var userHttpLink = new HttpLink({ uri: process.env.REACT_APP_USER_GQL_URI });
var userClient = new ApolloClient({
    link: from([authMiddleware, logrocketMiddleware, userHttpLink]),
    cache: new InMemoryCache(),
});
var geofenceHttpLink = new HttpLink({
    uri: "".concat(process.env.REACT_APP_GQL_URL, "/geofence/graphql"),
});
var geofenceClient = new ApolloClient({
    link: from([authMiddleware, geofenceHttpLink]),
    cache: new InMemoryCache({
        addTypename: false,
    }),
});
var notificationsHttpLink = new HttpLink({
    uri: "".concat(process.env.REACT_APP_GQL_URL, "/notification/graphql"),
});
var notificationClient = new ApolloClient({
    link: from([authMiddleware, notificationsHttpLink]),
    cache: new InMemoryCache({
        addTypename: false,
    }),
});
export function getDeviceClient() {
    return userClient;
}
export function getUserClient() {
    return userClient;
}
export function getGeofenceClient() {
    return geofenceClient;
}
export function getNotificationClient() {
    return notificationClient;
}
export function clearUserClientCache() {
    return getUserClient().clearStore();
}
