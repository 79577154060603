import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import Box from "@carrier/fds-react/Box";
import FormControl from "@carrier/fds-react/FormControl";
import MenuItem from "@carrier/fds-react/MenuItem";
// TODO: import Select from FDS when task https://carrier-digital.atlassian.net/browse/CARRIERIO-1320 will be implemented
import { Select } from "@material-ui/core";
import { StepSubHeader } from "../../..//NotificatioForm/Steps/StepSubHeader";
export var SelectAssets = function (_a) {
    var _b;
    var companies = _a.companies;
    var _c = useFormikContext(), values = _c.values, handleChange = _c.handleChange;
    var rootCompanies = useMemo(function () {
        return companies
            .filter(function (item) { return !item.parentId; })
            .sort(function (a, b) { return (a.name < b.name ? -1 : 1); });
    }, [companies]);
    return (React.createElement(Box, { sx: { maxWidth: "360px", marginBottom: "15px" } },
        React.createElement(StepSubHeader, { title: "*Rule applies to all assets" }),
        React.createElement(FormControl, { variant: "outlined", size: "small", fullWidth: true, sx: { marginTop: "10px" } },
            React.createElement(Select, { displayEmpty: true, value: (_b = values.assets.companyId) !== null && _b !== void 0 ? _b : "", name: "assets.companyId", onChange: handleChange }, rootCompanies.map(function (item) { return (React.createElement(MenuItem, { key: item.id, value: item.id }, item.name)); })))));
};
