import { TemperatureIcon } from "../components/icons/TemperatureIcon";
import { DoorIcon } from "../components/icons/DoorIcon";
import { PolygonIcon } from "../components/icons/PolygonIcon";
import { AlarmIcon } from "../components/icons/AlarmIcon";
export var STEP_LABELS = [
    "Create Rules",
    "Select Assets",
    "Choose Delivery",
    "Review",
];
export var NOTIFICATION_EVENT_MENU_ITEMS = {
    TEMPERATURE_DEVIATION: {
        title: "Temperature deviation",
        icon: TemperatureIcon,
    },
    DOOR: {
        title: "Door",
        icon: DoorIcon,
    },
    GEOFENCE: {
        title: "Geofence",
        icon: PolygonIcon,
    },
    FREEZER_MODE: {
        title: "",
        icon: PolygonIcon,
    },
    TRU_STATUS: {
        title: "",
        icon: PolygonIcon,
    },
    TRU_ALARM: {
        title: "Active alarms",
        icon: AlarmIcon,
    },
};
