import React, { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@carrier/fds-react/IconButton";
import { isDoorEvent, isGeofenceEvent, isTemperatureDeviationEvent, isTruAlarmEvent, } from "../../../utils";
import { TemperatureDeviationEventView } from "../../NotificatioForm/Event/EventViews/TemperatureDeviation";
import { GeofenceEventView } from "../../NotificatioForm/Event/EventViews/Geofence";
import { AlarmEventView } from "../../NotificatioForm/Event/EventViews/Alarm";
import { EventModal } from "./EventModal";
import { DoorEventView } from "./EventViews/Door";
import styles from "./styles.module.scss";
import { useEventModal } from "@/features/notifications/hooks/useEventModal";
export var NotificationEventManager = function (_a) {
    var event = _a.event, onEdit = _a.onEdit, onRemove = _a.onRemove;
    var _b = useEventModal(), currentModal = _b.currentModal, handleOpenModal = _b.handleOpenModal, handleCloseModal = _b.handleCloseModal;
    var handleSave = useCallback(function (newEvent) {
        onEdit(newEvent);
        handleCloseModal();
    }, [handleCloseModal, onEdit]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.eventManager },
            isDoorEvent(event) && (React.createElement(DoorEventView, { expression: event.expression })),
            isTemperatureDeviationEvent(event) && (React.createElement(TemperatureDeviationEventView, { expression: event.expression })),
            isGeofenceEvent(event) && (React.createElement(GeofenceEventView, { expression: event.expression })),
            isTruAlarmEvent(event) && (React.createElement(AlarmEventView, { expression: event.expression })),
            React.createElement("div", { className: "actions" },
                React.createElement(IconButton, { size: "small", onClick: function () {
                        onRemove();
                    } },
                    React.createElement(CloseIcon, null)),
                React.createElement(IconButton, { size: "small", onClick: function () {
                        handleOpenModal(event.type);
                    } },
                    React.createElement(EditIcon, null)))),
        React.createElement(EventModal, { initialValue: event, modal: currentModal, handleCancel: handleCloseModal, handleOk: handleSave })));
};
