import React from "react";
import styles from "./styles.module.scss";
import { toFahrenheitDelta, useTypedSelector } from "@/utils";
import { TemperatureIcon } from "@/features/notifications/components/icons/TemperatureIcon";
export var TemperatureDeviationEventView = function (_a) {
    var expression = _a.expression;
    var userSettings = useTypedSelector(function (state) { return state.userSettings.data; });
    var comparison = expression.comparison.replace(/_/g, " ");
    return (React.createElement("div", { className: styles.textBlock },
        React.createElement(TemperatureIcon, { className: styles.icon }),
        React.createElement("div", null, "if return air is"),
        React.createElement("div", { className: styles.condition },
            React.createElement("b", null, comparison)),
        React.createElement("div", null, "the setpoint by"),
        React.createElement("div", { className: styles.condition },
            React.createElement("b", null,
                userSettings.measurementTemperature === "C"
                    ? expression.value.toFixed(2)
                    : toFahrenheitDelta(expression.value),
                "\u00A0 \u00B0",
                userSettings.measurementTemperature))));
};
