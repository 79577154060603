import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
export function LogoutPage(props) {
    var oktaAuth = useOktaAuth().oktaAuth;
    var DialogPageLayout = props.layouts.DialogPageLayout;
    useEffect(function () {
        oktaAuth.signOut();
    });
    return React.createElement(DialogPageLayout, { content: "Logging out…" });
}
