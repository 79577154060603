import { NotificationsPage } from "../components";
import NotificationsIconAssetUrl from "../assets/notifications-icon.svg";
export var routes = [
    {
        path: "/notifications",
        exact: true,
        treatAsProtectedWhenAuthenticationIsAvailable: true,
        component: NotificationsPage,
        navigationItem: {
            label: "Notifications",
            icon: NotificationsIconAssetUrl,
            weight: 60000,
            external: false,
        },
    },
];
