import React from "react";
import Grid from "@carrier/fds-react/Grid";
import Typography from "@carrier/fds-react/Typography";
import Box from "@carrier/fds-react/Box";
import Link from "@carrier/fds-react/Link";
export var DrawerFooter = function () { return (React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "center", sx: { paddingBottom: "12px" } },
    React.createElement(Box, null,
        React.createElement(Typography, { variant: "caption" },
            "\u00A9 ",
            new Date().getFullYear(),
            " Carrier")),
    React.createElement(Box, { display: "flex" },
        React.createElement(Link, { href: "https://www.carrier.com/lynx/terms-of-use", variant: "caption", target: "_blank", underline: "none" }, "Terms of Service")),
    React.createElement(Box, null,
        React.createElement(Link, { href: "https://www.carrier.com/lynx/privacy", variant: "caption", target: "_blank", underline: "none" }, "Privacy")))); };
