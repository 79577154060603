import React from "react";
import { FieldArray } from "formik";
import FormHelperText from "@carrier/fds-react/FormHelperText";
import { AddEventButton } from "../../Event/AddEventButton";
import { NotificationEventManager } from "../../Event/EventManager";
export var RuleConditions = function (_a) {
    var conditions = _a.conditions, fieldName = _a.fieldName, allowedEvents = _a.allowedEvents, error = _a.error;
    return (React.createElement(FieldArray, { name: fieldName, render: function (arrayHelpers) {
            return conditions.length > 0 ? (conditions.map(function (rule, index) { return (React.createElement(NotificationEventManager, { onRemove: function () { return arrayHelpers.remove(index); }, onEdit: function (event) { return arrayHelpers.replace(index, event); }, key: index.toString() + rule.type, event: rule })); })) : (React.createElement(React.Fragment, null,
                React.createElement(AddEventButton, { onAdd: function (event) {
                        return arrayHelpers.push(event);
                    }, events: allowedEvents }),
                error && React.createElement(FormHelperText, { error: true }, error)));
        } }));
};
