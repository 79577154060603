import { useCallback, useState } from "react";
import { getCompanies } from "../api";
export var useFetchCompanies = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), companies = _b[0], setCompanies = _b[1];
    var fetch = useCallback(function () {
        setLoading(true);
        getCompanies()
            .then(function (_a) {
            var _b, _c;
            var data = _a.data;
            var pageCompanies = data.pageCompanies;
            if (pageCompanies.success) {
                setCompanies((_b = pageCompanies.docs) !== null && _b !== void 0 ? _b : []);
            }
            else {
                throw new Error((_c = pageCompanies.error) !== null && _c !== void 0 ? _c : "Error");
            }
            setLoading(false);
        })
            .catch(function (error) {
            setLoading(false);
            throw error;
        });
    }, []);
    return {
        loading: loading,
        companies: companies,
        fetch: fetch,
    };
};
