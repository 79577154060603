import React from "react";
import { styled } from "@mui/system";
import Typography from "@carrier/fds-react/Typography";
import { actionColors, textColors } from "@/theme/constants";
import { TextColorEnum } from "@/theme/types";
var TypographyStyled = styled(Typography)({
    color: textColors[TextColorEnum.Disabled],
    "& kbd": {
        padding: "0.2rem 0.4rem",
        fontSize: "0.5rem",
        borderRadius: "0.28rem",
        color: textColors[TextColorEnum.Secondary],
        backgroundColor: actionColors.disabled,
    },
});
export var Hint = function () {
    return (React.createElement(TypographyStyled, { variant: "caption", dangerouslySetInnerHTML: {
            __html: "Use <kbd>CTRL</kbd>+click or <kbd>SHIFT</kbd>+click for extended multiselect.",
        } }));
};
