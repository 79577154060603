export var getUpdatedSettings = function (email, userSettings) { return ({
    email: email,
    language: userSettings.language,
    timezone: userSettings.timezone || "",
    measurementTemperature: userSettings.measurementTemperature,
    measurementDistance: userSettings.measurementDistance,
    measurementVolume: userSettings.measurementVolume,
    measurementSpeed: userSettings.measurementSpeed,
    notificationEnabled: userSettings.notificationEnabled,
}); };
