import { throttle } from "lodash";
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../../api";
import { defaultTableColumns } from "./columns";
import { EmailFormatter, RulesFormatter, StatusFormatter, TypeFormatter, } from "./column-formatters";
import { NotificationsTableLoadingOverlay } from "./LoadingOverlay";
import { AgGridReact } from "@/utils/ag-grid";
export function NotificationsTable() {
    // const count = useSelector(selectCount);
    var notifications = useSelector(function (state) { return ({
        notifications: state.notifications,
    }); }, shallowEqual).notifications;
    var dispatch = useDispatch();
    var t = function (x) { return x; }; // XXX
    // const defaultColumns = useMemo(() => defaultTableColumns(userSettings, t), [t, userSettings]);
    var defaultColumns = useMemo(function () { return defaultTableColumns(); }, [t, {}]);
    var agGridApi = null;
    var onGridReady = function (params) {
        agGridApi = params.api;
    };
    var onFirstDataRendered = function (params) {
        params.api.sizeColumnsToFit();
    };
    useEffect(function () {
        dispatch(fetchNotifications());
    }, [dispatch]);
    // @ts-ignore-next-line
    useEffect(function () {
        var debouncedHandleResize = throttle(function () {
            if (agGridApi) {
                agGridApi.sizeColumnsToFit();
            }
        }, 50);
        window.addEventListener("resize", debouncedHandleResize);
        return function () {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [window.innerHeight, window.innerWidth]);
    return (React.createElement(React.Fragment, null,
        React.createElement(AgGridReact, { rowData: notifications.entities, columnDefs: defaultColumns, suppressFieldDotNotation: true, pagination: false, immutableData: true, rowSelection: "single", suppressRowClickSelection: true, getRowNodeId: function (data) { return data.notificationId; }, paginationAutoPageSize: true, onGridReady: onGridReady, onFirstDataRendered: onFirstDataRendered, 
            // onColumnMoved={onColumnsChanged}
            // onSortChanged={onColumnsChanged}
            // onColumnPinned={onColumnsChanged}
            // onColumnVisible={onColumnsChanged}
            // getMainMenuItems={getMainMenuItems}
            suppressDragLeaveHidesColumns: true, frameworkComponents: {
                EmailFormatter: function (params) {
                    return EmailFormatter(params);
                },
                StatusFormatter: function (params) {
                    return StatusFormatter(params);
                },
                TypeFormatter: function (params) { return TypeFormatter(params); },
                RulesFormatter: function () { return RulesFormatter(); },
                // RulesFormatter: (params: ICellRendererParams) =>
                //   RulesFormatter(params, () => handleEditClick(params.data.notificationId)),
                // NotificationsTableActions: (params: ICellRendererParams) =>
                //   NotificationsTableActions(params, {
                //     onEdit: handleEditClick,
                //     onDelete: handleDeleteClick,
                //     onActiveToggle: handleActiveToggle,
                //   }),
                LoadingOverlay: NotificationsTableLoadingOverlay,
            }, loadingOverlayComponent: "LoadingOverlay", pivotPanelShow: "always", skipHeaderOnAutoSize: true, defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { textAlign: "center" },
            } })));
}
