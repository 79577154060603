import { configureStore } from "@reduxjs/toolkit";
import { userSettingsReducer } from "@/features/user-profile/stores/userSettingsSlice";
import { assetsReducer } from "@/features/assets/stores";
import { notificationsReducer } from "@/features/notifications/stores";
import { companiesReducer } from "@/features/company-management/stores/companiesSlice";
import { authReducer } from "@/features/authentication/stores/authSlice";
import { rootReducer } from "@/stores/root";
import { geofenceReducer } from "@/features/geofence/stores";
import { geofenceGroupsReducer } from "@/features/geofence-groups/stores";
export var store = configureStore({
    reducer: {
        auth: authReducer,
        userSettings: userSettingsReducer,
        companies: companiesReducer,
        assets: assetsReducer,
        notifications: notificationsReducer,
        root: rootReducer,
        geofences: geofenceReducer,
        geofenceGroups: geofenceGroupsReducer,
    },
});
