import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@carrier/fds-react/Typography";
import IconButton from "@carrier/fds-react/IconButton";
import TextField from "@carrier/fds-react/TextField";
import Grid from "@carrier/fds-react/Grid";
import InputAdornment from "@carrier/fds-react/InputAdornment";
import { Hint } from "@/features/company-management/components/companyDialogue/Hint";
export var DrawerHeader = function (_a) {
    var onClose = _a.onClose, searchText = _a.searchText, onSearch = _a.onSearch;
    return (React.createElement(Grid, { container: true, direction: "column", rowSpacing: 3 },
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { direction: "row", container: true, justifyContent: "space-between", alignItems: "flex-start", rowSpacing: 3 },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "subtitle2" }, "Select Company")),
                React.createElement(Grid, { item: true },
                    React.createElement(IconButton, { sx: { width: 30, height: 30, borderRadius: 1 }, onClick: onClose },
                        React.createElement(CloseIcon, null))))),
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, direction: "column", justifyContent: "flex-start", alignItems: "space-between", rowSpacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { placeholder: "Search Company", InputProps: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start", disablePointerEvents: true },
                                React.createElement(SearchIcon, null))),
                        }, sx: {
                            width: 290,
                            borderRadius: 1,
                        }, hideBackgroundColor: true, showBorder: true, size: "small", value: searchText, onChange: onSearch })),
                React.createElement(Grid, { item: true },
                    React.createElement(Hint, null))))));
};
