import React from "react";
import { Link } from "react-router-dom";
// FDS
import Tooltip from "@carrier/fds-react/Tooltip";
// MUI
// TODO use Zoom from FDS when it will be added
// https://carrier-digital.atlassian.net/browse/CARRIERIO-1031
import { Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LynxLogo from "@/assets/svgs/lynx-logo.svg";
import { useTooltipStyles } from "@/utils";
var useStyles = makeStyles(function () { return ({
    logoContainer: {
        display: "flex",
        flex: 0,
        justifyContent: "center",
    },
    logoImage: {
        margin: "8px",
    },
}); });
export var Logo = function () {
    var tooltipClasses = useTooltipStyles();
    var classes = useStyles();
    return (React.createElement(Tooltip, { classes: tooltipClasses, title: "Lynx Home", placement: "right", arrow: true, enterDelay: 800, TransitionComponent: Zoom },
        React.createElement(Link, { to: "/assets", className: classes.logoContainer },
            React.createElement("img", { className: classes.logoImage, src: LynxLogo, alt: "Lynx" }))));
};
