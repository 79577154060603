var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import Box from "@carrier/fds-react/Box";
import Stepper from "@carrier/fds-react/Stepper";
import Step from "@carrier/fds-react/Step";
import StepLabel from "@carrier/fds-react/StepLabel";
import StepContent from "@carrier/fds-react/StepContent";
import Typography from "@carrier/fds-react/Typography";
import { useDispatch } from "react-redux";
import CircularProgress from "@carrier/fds-react/CircularProgress";
import { SelectAssets } from "../NotificatioForm/Steps/SelectAssets/SelectAssets";
import { NotificationReviewStep } from "..//NotificatioForm/Steps/ReviewStep/NotificationReviewStep";
import { StepFooter } from "../NotificatioForm/Steps/StepFooter";
import { RulesStep } from "../NotificatioForm/Steps/RulesStep/RulesStep";
import { ChooseDeliveryStep } from "../NotificatioForm/Steps/ChooseDeliveryStep/ChooseDeliveryStep";
import { STEP_LABELS } from "@/features/notifications/constants";
import { fetchGeofences } from "@/features/geofence/stores";
import { fetchGeofenceGroups } from "@/features/geofence-groups/stores";
import { useFetchCompanies } from "@/features/notifications/hooks/useFetchCompanies";
import { timeToSeconds } from "@/utils";
import { createNotificationAction } from "@/features/notifications/stores/actions";
export var Steps = function (_a) {
    var updateStep = _a.updateStep, currentStep = _a.currentStep, initialValues = _a.initialValues, onClose = _a.onClose;
    var dispatch = useDispatch();
    var _b = useState(false), submitting = _b[0], setSubmitting = _b[1];
    var _c = useFetchCompanies(), fetchCompanies = _c.fetch, companies = _c.companies, loading = _c.loading;
    // TODO: replace it with a validation schema (yup validation)
    var validateFormData = useCallback(function (_a) {
        var name = _a.name, conditions = _a.conditions;
        var errors = {};
        if (!name) {
            errors.name = "Notification name is required";
            updateStep(0);
        }
        if (conditions.length === 0) {
            errors.conditions = "Notification condition is required";
            updateStep(0);
        }
        return errors;
    }, []);
    var handleSubmit = useCallback(function (_a, _b) {
        var name = _a.name, conditions = _a.conditions, exceptConditions = _a.exceptConditions, enableTimeCondition = _a.enableTimeCondition, time = _a.time, assets = _a.assets, recipients = _a.recipients, sendEmail = _a.sendEmail;
        var resetForm = _b.resetForm, setErrors = _b.setErrors;
        var rule = {
            condition: conditions[0],
            exceptConditions: exceptConditions,
        };
        if (enableTimeCondition) {
            rule = __assign(__assign({}, rule), { onlySendWhenConditionMetActive: enableTimeCondition });
            if (time) {
                rule = __assign(__assign({}, rule), { onlySendWhenConditionMetSeconds: timeToSeconds(time.hr, time.min) });
            }
        }
        var payload = {
            name: name,
            active: true,
            sendEmail: sendEmail,
            recipients: recipients,
            rule: rule,
            assets: assets,
        };
        setSubmitting(true);
        // TODO: move it to state
        dispatch(createNotificationAction(payload))
            // @ts-ignore
            .then(function () {
            setSubmitting(false);
            onClose();
            resetForm();
            updateStep(0);
        })
            .catch(function (error) {
            setSubmitting(false);
            if (error === "notification_with_such_name_already_exists") {
                setErrors({ name: "Notification name already exists" });
                updateStep(0);
            }
        });
    }, [dispatch, onClose]);
    var getStepContent = function (step) {
        var obj = {
            0: React.createElement(RulesStep, null),
            1: React.createElement(SelectAssets, { companies: companies }),
            2: React.createElement(ChooseDeliveryStep, null),
            3: React.createElement(NotificationReviewStep, null),
        };
        return obj[step] || React.createElement(NotificationReviewStep, null);
    };
    useEffect(function () {
        dispatch(fetchGeofences());
        dispatch(fetchGeofenceGroups());
        fetchCompanies();
    }, [dispatch]);
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: handleSubmit, validate: validateFormData, validateOnChange: false, validateOnBlur: false, enableReinitialize: true },
        React.createElement(Box, { sx: { height: "730px" } }, loading ? (React.createElement(CircularProgress, { color: "primary", sx: { margin: "24px" } })) : (React.createElement(Stepper, { activeStep: currentStep, orientation: "vertical", sx: { padding: "40px 48px" } }, STEP_LABELS.map(function (label, index) { return (React.createElement(Step, { key: label },
            React.createElement(StepLabel, null,
                React.createElement(Typography, { variant: "subtitle1" },
                    React.createElement("strong", null, label))),
            React.createElement(StepContent, { sx: {
                    display: "flex",
                    flexDirection: "column",
                } },
                getStepContent(index),
                React.createElement(StepFooter, { currentStep: currentStep, updateStep: updateStep, submitting: submitting })))); }))))));
};
