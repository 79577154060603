export var styles = {
    intercomButton: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "32px",
        "& .MuiButton-label": {
            height: "24px",
            "& .MuiSvgIcon-root": {
                height: "24px",
                width: "24px",
            },
        },
    },
};
