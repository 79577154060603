import React from "react";
import { IntercomProvider as Provider, useIntercom } from "react-use-intercom";
import { useOktaAuth } from "@okta/okta-react";
var REACT_APP_INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;
var IntercomBoot = function () {
    var _a;
    var authState = useOktaAuth().authState;
    var boot = useIntercom().boot;
    if (REACT_APP_INTERCOM_ID !== "") {
        try {
            var _b = ((_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims) || {}, name_1 = _b.name, email = _b.email;
            boot({
                name: name_1,
                email: email,
                date: new Date().toISOString(),
                customLauncherSelector: "#intercomButton",
                hideDefaultLauncher: true,
            });
        }
        catch (error) {
            console.warn("Unable to start intercom chat because ".concat(error));
        }
    }
    return React.createElement(React.Fragment, null);
};
export var IntercomProvider = function (_a) {
    var children = _a.children;
    return (React.createElement(Provider, { appId: REACT_APP_INTERCOM_ID || "" },
        React.createElement(IntercomBoot, null),
        children));
};
