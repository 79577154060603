import React, { useCallback, useMemo, useRef } from "react";
import Typography from "@carrier/fds-react/Typography";
// @ts-ignore
import Incrementer from "@carrier/fds-react/patterns/Incrementer/Incrementer";
import { Dialog } from "../../../NotificatioForm/Event/Dialog";
import { Dropdown } from "../../../NotificatioForm/Dropdown/Dropdown";
import { ActionButtons } from "../../../NotificatioForm/Event/Modals/ActionButtons";
import styles from "./styles.module.scss";
import { useDropdownOption } from "@/features/notifications/hooks/useDropdownOptions";
import { toCelsiusDelta, toFahrenheitDelta, useTypedSelector } from "@/utils";
export var TemperatureDeviationDialog = function (_a) {
    var _b, _c;
    var open = _a.open, handleCancel = _a.handleCancel, handleOk = _a.handleOk, initialValue = _a.initialValue;
    var userSettings = useTypedSelector(function (state) { return state.userSettings.data; });
    var initialTemperature = ((_b = initialValue === null || initialValue === void 0 ? void 0 : initialValue.expression) === null || _b === void 0 ? void 0 : _b.value) || 0;
    var temperatureValueRef = useRef(userSettings.measurementTemperature === "C"
        ? +initialTemperature.toFixed(2)
        : toFahrenheitDelta(initialTemperature));
    var dropdownItems = useMemo(function () { return [
        {
            label: "Above",
            value: "ABOVE",
        },
        {
            label: "Below",
            value: "BELOW",
        },
        {
            label: "Above Or Below",
            value: "ABOVE_OR_BELOW",
        },
    ]; }, []);
    var initialComparison = ((_c = initialValue === null || initialValue === void 0 ? void 0 : initialValue.expression) === null || _c === void 0 ? void 0 : _c.comparison) ||
        dropdownItems[0].value;
    var _d = useDropdownOption(initialComparison), option = _d.option, handleOptionChange = _d.handleOptionChange;
    var handleSave = useCallback(function () {
        handleOk({
            type: "TEMPERATURE_DEVIATION",
            expression: {
                comparison: option,
                value: userSettings.measurementTemperature === "C"
                    ? temperatureValueRef.current
                    : toCelsiusDelta(temperatureValueRef.current),
            },
        });
    }, [handleOk, option, userSettings.measurementTemperature]);
    return (React.createElement(Dialog, { open: open, onClose: handleCancel, dialogTitle: "Temperature deviation", fullWidth: true, maxWidth: "sm", content: React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: styles.textBlock, variant: "body1", component: "span" },
                React.createElement("div", null, "if return air is"),
                React.createElement(Dropdown, { value: option, onChange: handleOptionChange, items: dropdownItems }),
                React.createElement("div", { style: { marginRight: "10px" } }, "the setpoint by"),
                React.createElement(Incrementer, { defaultValue: temperatureValueRef.current, onChange: function (value) {
                        return (temperatureValueRef.current = value);
                    }, lowerLimit: 0, prefix: "\u00B0".concat(userSettings.measurementTemperature) }))), actions: React.createElement(ActionButtons, { handleCancel: handleCancel, handleSave: handleSave }) }));
};
