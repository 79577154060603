import React, { useEffect, useState } from "react";
import Drawer from "@carrier/fds-react/Drawer";
import IconButton from "@carrier/fds-react/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { HiddenScroll } from "@/components/HiddenScroll";
import { PageDrawerEvent } from "@/events";
var duration = 50;
var useStyles = makeStyles(function () { return ({
    celsiusDrawer: {
        width: "440px",
        height: "100%",
        transition: "width ".concat(duration, "ms cubic-bezier(0, 0, 0.2, 1) 0ms"),
        "& .MuiDrawer-paper": {
            position: "static",
            width: "100%",
            padding: "20px",
        },
    },
    celsiusDrawerClosed: {
        width: "0",
    },
    toggleButton: {
        transition: "left ".concat(duration, "ms cubic-bezier(0, 0, 0.2, 1) 0ms"),
        position: "fixed",
        top: "120px",
        color: "#1d1d1d",
        backgroundColor: "#fff",
        width: "30px",
        height: "30px",
        border: "1px solid #1d1d1d",
        zIndex: 12,
        borderRadius: "0.42rem",
        "&:hover": {
            backgroundColor: "#e3e3e3",
        },
    },
    toggleButtonOpened: {
        left: "500px",
    },
    toggleButtonClosed: {
        left: "60px",
    },
}); });
export var ContentDrawer = function (_a) {
    var _b, _c;
    var _d = _a.defaultValue, defaultValue = _d === void 0 ? true : _d, children = _a.children, onChange = _a.onChange;
    var _e = useState(defaultValue), open = _e[0], setOpen = _e[1];
    var classes = useStyles();
    var toggle = function () {
        setOpen(function (prev) {
            var currentValue = !prev;
            if (onChange) {
                onChange(currentValue);
            }
            return currentValue;
        });
    };
    var handleButtonClick = function () {
        toggle();
    };
    useEffect(function () {
        PageDrawerEvent.subscribe(toggle);
        return function () {
            PageDrawerEvent.unsubscribe();
        };
    }, []);
    var buttonClassNames = clsx(classes.toggleButton, (_b = {},
        _b[classes.toggleButtonOpened] = open,
        _b[classes.toggleButtonClosed] = !open,
        _b));
    var drawerClassNames = clsx(classes.celsiusDrawer, (_c = {},
        _c[classes.celsiusDrawerClosed] = !open,
        _c));
    return (React.createElement("div", null,
        React.createElement(Drawer, { elevation: 0, anchor: "left", variant: "persistent", className: drawerClassNames, transitionDuration: duration, open: open },
            React.createElement(HiddenScroll, null, children && React.createElement(React.Fragment, null, children))),
        React.createElement(IconButton, { color: "secondary", "aria-label": "close widgets", className: buttonClassNames, onClick: handleButtonClick }, open ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null))));
};
