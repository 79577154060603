import React, { useCallback, useState } from "react";
import { createStyles, makeStyles, Grid, IconButton, Menu, MenuItem, Switch, } from "@material-ui/core";
// import { useTranslation } from 'react-i18next';
var useStyles = makeStyles(function () {
    return createStyles({
        iconButton: {
            display: "block",
        },
        menuItem: {
            fontSize: 15,
            color: "#000000DE",
        },
    });
});
export function NotificationsTableActions(_a, _b) {
    var data = _a.data;
    var onDelete = _b.onDelete, onEdit = _b.onEdit, onActiveToggle = _b.onActiveToggle;
    var classes = useStyles();
    // const { t } = useTranslation();
    var t = function (x) { return x; };
    var _c = useState(data.active), isActive = _c[0], setIsActive = _c[1];
    var _d = React.useState(null), anchorEl = _d[0], setAnchorEl = _d[1];
    var open = Boolean(anchorEl);
    var handleClick = useCallback(function (event) {
        setAnchorEl(event.currentTarget);
    }, []);
    var handleClose = useCallback(function () {
        setAnchorEl(null);
    }, []);
    var handleEditClick = useCallback(function () {
        handleClose();
        onEdit(data.notificationId);
    }, [data.notificationId, handleClose, onEdit]);
    var handleDeleteClick = useCallback(function () {
        handleClose();
        onDelete(data.notificationId);
    }, [data.notificationId, handleClose, onDelete]);
    var handleActiveToggle = useCallback(function (event) {
        try {
            setIsActive(event.target.checked);
            onActiveToggle(data.notificationId, event.target.checked);
        }
        catch (e) {
            setIsActive(false);
        }
    }, [data.notificationId, onActiveToggle]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { className: classes.iconButton, onClick: handleClick }, "..."),
        React.createElement(Menu, { id: "long-menu", anchorEl: anchorEl, keepMounted: true, open: open, onClose: handleClose },
            React.createElement(MenuItem, { className: classes.menuItem, onClick: handleEditClick }, t("common.edit")),
            React.createElement(MenuItem, { className: classes.menuItem },
                React.createElement(Grid, { component: "label", container: true, alignItems: "center", spacing: 1 },
                    React.createElement(Grid, { item: true }, t("common.inactive")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Switch, { checked: isActive, onChange: handleActiveToggle })),
                    React.createElement(Grid, { item: true }, t("common.active")))),
            React.createElement(MenuItem, { className: classes.menuItem, onClick: handleDeleteClick }, t("common.delete")))));
}
