var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OpaSubjectType } from "@/constants";
import { inAllGroups, inAnyGroup, isCarrierAdmin, isTechnician, UserGroups, getDefaultOpaPayload, } from "@/utils";
var NavigationItemKeys = {
    ASSET_TRACKING: "ASSET_TRACKING",
    COMPANY_MANAGEMENT: "COMPANY_MANAGEMENT",
    DEVICE_MANAGEMENT: "DEVICE_MANAGEMENT",
    ADVANCED_TRACKING: "ADVANCED_TRACKING",
    COMMAND_HISTORY: "COMMAND_HISTORY",
    API_PORTAL: "API_PORTAL",
    NOTIFICATIONS: "NOTIFICATIONS",
    ADVANCED_COMPANY_MANAGEMENT: "ADVANCED_COMPANY_MANAGEMENT",
};
// TODO rework navigation filtering by access
export function filterNavigationItemsAccordingToAccess(navigationItems, _a) {
    var _b;
    var tenant = _a.tenant, authState = _a.authState, hasPermission = _a.hasPermission;
    var tenantId = tenant.id;
    var CarrierAdmin = UserGroups.CarrierAdmin, CommandAdmin = UserGroups.CommandAdmin, CarrierTechnician = UserGroups.CarrierTechnician, FleetAdmin = UserGroups.FleetAdmin, APIPortalAdmin = UserGroups.APIPortalAdmin;
    var rbacOptions = {
        subjectId: tenantId,
        subjectType: OpaSubjectType.COMPANY,
    };
    var haveAccessToCompanyManagement = isCarrierAdmin(authState) &&
        hasPermission(getDefaultOpaPayload("company.list", tenantId));
    // TODO remove it after full OPA intergration
    var isCommandHistoryInAllowedGroup = inAllGroups(authState, [CarrierAdmin, CommandAdmin]) ||
        inAllGroups(authState, [CarrierTechnician, CommandAdmin]) ||
        inAllGroups(authState, [FleetAdmin, CommandAdmin]);
    var oktaAuthAvailablePages = (_b = {},
        _b[NavigationItemKeys.ASSET_TRACKING] = hasPermission(__assign({ action: "dashboard.assetList" }, rbacOptions)),
        _b[NavigationItemKeys.COMPANY_MANAGEMENT] = haveAccessToCompanyManagement,
        _b[NavigationItemKeys.DEVICE_MANAGEMENT] = isCarrierAdmin(authState) || isTechnician(authState),
        _b[NavigationItemKeys.ADVANCED_TRACKING] = inAnyGroup(authState, [CarrierAdmin, FleetAdmin]) &&
            hasPermission(__assign({ action: "wialon.view" }, rbacOptions)) &&
            tenant.advancedTrackingEnabled,
        _b[NavigationItemKeys.COMMAND_HISTORY] = isCommandHistoryInAllowedGroup &&
            hasPermission(getDefaultOpaPayload("2WayCmd.historyList", tenantId)),
        _b[NavigationItemKeys.API_PORTAL] = Boolean(process.env.REACT_APP_API_PORTAL &&
            inAllGroups(authState, [APIPortalAdmin])),
        _b[NavigationItemKeys.ADVANCED_COMPANY_MANAGEMENT] = haveAccessToCompanyManagement,
        _b);
    return navigationItems
        .filter(function (navigationItem) {
        // @ts-ignore-next-line
        return NavigationItemKeys[navigationItem.key];
    })
        .filter(function (navigationItem) {
        return oktaAuthAvailablePages[navigationItem.key] !== undefined
            ? oktaAuthAvailablePages[navigationItem.key]
            : true;
    });
}
