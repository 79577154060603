var _a;
import { TransformEnum, TypographyEnum, WeightEnum, } from "@/theme/types/typography";
export var typography = (_a = {},
    _a[TypographyEnum.H1] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 41,
        textTransform: "none",
        lineHeight: "inherit",
        letterSpacing: 0,
    },
    _a[TypographyEnum.H2] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 32,
        textTransform: TransformEnum.None,
        lineHeight: "inherit",
        letterSpacing: 0,
    },
    _a[TypographyEnum.H3] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 28,
        textTransform: TransformEnum.None,
        lineHeight: "inherit",
        letterSpacing: 0,
    },
    _a[TypographyEnum.H4] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 24,
        textTransform: TransformEnum.None,
        lineHeight: "inherit",
        letterSpacing: 0,
    },
    _a[TypographyEnum.H5] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 19,
        textTransform: TransformEnum.None,
        lineHeight: "inherit",
        letterSpacing: 0,
    },
    _a[TypographyEnum.H6] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 18,
        textTransform: TransformEnum.None,
        lineHeight: "inherit",
        letterSpacing: 0,
    },
    _a[TypographyEnum.Subtitle1] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 17,
        textTransform: TransformEnum.None,
        lineHeight: 20,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Subtitle2] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 15,
        textTransform: TransformEnum.None,
        lineHeight: 16,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Body1] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 17,
        textTransform: TransformEnum.None,
        lineHeight: 24,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Body2] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 15,
        textTransform: TransformEnum.None,
        lineHeight: 18,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Caption] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 12,
        textTransform: TransformEnum.None,
        lineHeight: 14,
        letterSpacing: 0.15,
    },
    _a[TypographyEnum.Overline] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 12,
        textTransform: TransformEnum.Uppercase,
        lineHeight: 24,
        letterSpacing: 0.15,
    },
    _a[TypographyEnum.ButtonLarge] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 17,
        textTransform: TransformEnum.Capitalize,
        lineHeight: 26,
        letterSpacing: 0,
    },
    _a[TypographyEnum.ButtonMedium] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 15,
        textTransform: TransformEnum.Capitalize,
        lineHeight: 24,
        letterSpacing: 0,
    },
    _a[TypographyEnum.ButtonSmall] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 14,
        textTransform: TransformEnum.Capitalize,
        lineHeight: 22,
        letterSpacing: 0,
    },
    _a[TypographyEnum.AvatarLetter] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 18,
        textTransform: TransformEnum.Uppercase,
        lineHeight: 20,
        letterSpacing: 0.5,
    },
    _a[TypographyEnum.InputLabel] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 12,
        textTransform: TransformEnum.None,
        lineHeight: 12,
        letterSpacing: 0,
    },
    _a[TypographyEnum.HelperText] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 12,
        textTransform: TransformEnum.None,
        lineHeight: 20,
        letterSpacing: 0.15,
    },
    _a[TypographyEnum.InputText1] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 17,
        textTransform: TransformEnum.None,
        lineHeight: 20,
        letterSpacing: 0,
    },
    _a[TypographyEnum.InputText2] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 15,
        textTransform: TransformEnum.None,
        lineHeight: 16,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Badge] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 12,
        textTransform: TransformEnum.None,
        lineHeight: 14,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Chip] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 14,
        textTransform: TransformEnum.None,
        lineHeight: 18,
        letterSpacing: 0,
    },
    _a[TypographyEnum.Tooltip] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 12,
        textTransform: TransformEnum.None,
        lineHeight: 14,
        letterSpacing: 0.25,
    },
    _a[TypographyEnum.XSLabel] = {
        fontWeight: WeightEnum.Regular,
        fontSize: 10,
        textTransform: TransformEnum.None,
        lineHeight: 12,
        letterSpacing: 0,
    },
    _a);
