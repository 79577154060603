import { AssetsPage } from "../components";
import AssetsIconAssetUrl from "../assets/assets-icon.svg";
export var routes = [
    {
        path: "/assets",
        exact: true,
        treatAsProtectedWhenAuthenticationIsAvailable: true,
        component: AssetsPage,
        navigationItem: {
            icon: AssetsIconAssetUrl,
            label: "Asset Tracking",
            weight: 20000,
        },
    },
    {
        redirect: true,
        from: "/",
        to: "/assets",
        treatAsProtectedWhenAuthenticationIsAvailable: true,
    },
];
