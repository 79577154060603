import { useCallback, useState } from "react";
export function useDropdownOption(initialValue) {
    var _a = useState(initialValue), option = _a[0], setOption = _a[1];
    var handleOptionChange = useCallback(function (newOption) {
        setOption(newOption);
    }, []);
    return {
        option: option,
        handleOptionChange: handleOptionChange,
    };
}
