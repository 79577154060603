export var styles = {
    layout: {
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        padding: "0",
        height: "100vh",
        width: "100%",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        flex: "1",
        height: "100vh",
    },
    content: {
        width: "100%",
        flex: "1",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
    },
};
