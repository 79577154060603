import React from "react";
// FDS
import Typography from "@carrier/fds-react/Typography";
import Grid from "@carrier/fds-react/Grid";
import Button from "@carrier/fds-react/Button";
// MUI
import { styled } from "@mui/material/styles";
import { IntercomButton } from "@/features/intercom/components/IntercomButton";
import { InteractiveMap } from "@/features/assets/components/InteractiveMap";
import { TabbedContainer } from "./Lists/TabbedContainer";
import { ContentDrawer, MainPageContent } from "@/components/PageContent";
import { Navigation } from "@/components/Navigator/Navigation";
import { Logo } from "@/components/Logo/Logo";
import { PageDrawerEvent } from "@/events";
import { styles } from "./styles";
var ButtonStyled = styled(Button)(styles.intercomButton);
export function AssetsPage(_a) {
    var BasicPageLayout = _a.layouts.BasicPageLayout, navigation = _a.navigation;
    var hasIntercom = window.carrierFeatures.includes("intercom");
    return (React.createElement(BasicPageLayout, { aside: React.createElement(React.Fragment, null,
            React.createElement(Logo, null),
            React.createElement(Navigation, { items: navigation, onClick: PageDrawerEvent.toggle }),
            hasIntercom && (
            // TODO remove the feature from bundle when it's turned off
            React.createElement(ButtonStyled, { id: "intercomButton", variant: "text" },
                React.createElement(IntercomButton, null)))), content: React.createElement(React.Fragment, null,
            React.createElement(ContentDrawer, null,
                React.createElement("div", { style: { height: "2000px" } },
                    React.createElement(Typography, { variant: "body1" }, "Mock content with vertical scroll"))),
            React.createElement(MainPageContent, null,
                React.createElement(Grid, { container: true, sx: {
                        height: "100%",
                    }, direction: "column" },
                    React.createElement(Grid, { item: true, sx: {
                            height: "50%",
                        } },
                        React.createElement(InteractiveMap, null)),
                    React.createElement(Grid, { item: true, sx: {
                            height: "50%",
                        } },
                        React.createElement(TabbedContainer, null))))) }));
}
