var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@mui/styles";
import Button from "@carrier/fds-react/Button";
import Snackbar from "@carrier/fds-react/Snackbar";
import Grid from "@carrier/fds-react/Grid";
import Drawer from "@carrier/fds-react/Drawer";
import Typography from "@carrier/fds-react/Typography";
import Avatar from "@carrier/fds-react/Avatar";
import { ExternalApiKey } from "./ExternalApiKey";
import { DrawerHeader } from "@/features/user-profile/components/settings/DrawerHeader";
import { DrawerFooter } from "@/features/user-profile/components/settings/DrawerFooter";
import { UserInfo } from "@/features/user-profile/components/settings/UserInfo";
import { SettingsForm } from "@/features/user-profile/components/settings/SettingsForm";
import { useTypedSelector } from "@/utils";
import { bootstrapColors, textColors, typography } from "@/theme/constants";
import { TextColorEnum } from "@/theme/types";
import { Z_INDEXES } from "@/constants";
var useStyles = makeStyles(function () { return ({
    drawer: {
        zIndex: Z_INDEXES.drawer,
        "& .MuiBackdrop-root": {
            background: "rgba(0,0,0,.1)",
        },
    },
    avatar: {
        background: "#c9f7f5",
        color: "#1bc5bd",
        marginLeft: "0.75rem",
        width: "35px",
        height: "35px",
        fontSize: typography.Subtitle1.fontSize,
    },
    snackbar: {
        "& .MuiSnackbarContent-root": {
            background: "rgb(49,49,50)",
        },
    },
}); });
export var UserSettings = function () {
    var _a, _b;
    var classes = useStyles();
    var authState = useOktaAuth().authState;
    var userSettings = useTypedSelector(function (state) { return state.userSettings.data; });
    var _c = useState(false), openDrawer = _c[0], setOpenDrawer = _c[1];
    var _d = useState({
        open: false,
        message: "",
    }), snackbarState = _d[0], setSnackbarState = _d[1];
    var userFullname = ((_b = (_a = authState === null || authState === void 0 ? void 0 : authState.idToken) === null || _a === void 0 ? void 0 : _a.claims) === null || _b === void 0 ? void 0 : _b.name) || "";
    var updateDrawerState = useCallback(function () {
        setOpenDrawer(function (prevValue) { return !prevValue; });
    }, []);
    var closeSnackbar = useCallback(function () {
        setSnackbarState(__assign(__assign({}, snackbarState), { open: false }));
    }, [snackbarState]);
    useEffect(function () {
        return function () { return setOpenDrawer(false); };
    }, [setOpenDrawer]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: updateDrawerState, variant: "text", color: "secondary", sx: { fontWeight: 600 } },
            React.createElement(Typography, { variant: "subtitle2", sx: {
                    color: bootstrapColors.Secondary.containedHoverBackground,
                } }, "Hi,\u00A0"),
            React.createElement(Typography, { variant: "subtitle2", sx: {
                    color: textColors[TextColorEnum.Secondary],
                } }, userFullname),
            React.createElement(Avatar, { classes: { root: classes.avatar } }, userFullname[0])),
        React.createElement(Drawer, { anchor: "right", open: openDrawer, onBackdropClick: updateDrawerState, PaperProps: {
                sx: {
                    width: 375,
                    padding: "2.5rem 2.5rem 0 2.5rem",
                    overflow: "hidden",
                },
            }, className: classes.drawer },
            React.createElement(DrawerHeader, { onClose: updateDrawerState }),
            React.createElement(Grid, { container: true, sx: {
                    height: "calc(100% + 2.5rem)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    paddingTop: "1.25rem",
                    paddingRight: "1.25rem",
                }, direction: "column", justifyContent: "space-between" },
                React.createElement(Grid, { container: true },
                    React.createElement(UserInfo, null),
                    React.createElement(SettingsForm, { snackbarState: snackbarState, setSnackbarState: setSnackbarState }),
                    userSettings.restApiEnabled && (React.createElement(Grid, { container: true },
                        React.createElement(ExternalApiKey, null)))),
                React.createElement(Grid, { container: true },
                    React.createElement(Snackbar, { anchorOrigin: { vertical: "bottom", horizontal: "right" }, open: snackbarState.open, onClose: closeSnackbar, message: React.createElement(Typography, { variant: "caption" }, snackbarState.message), classes: { root: classes.snackbar } }))),
            React.createElement(DrawerFooter, null))));
};
