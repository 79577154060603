var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import IconButton from "@carrier/fds-react/IconButton";
import DialogTitle from "@carrier/fds-react/DialogTitle";
import DialogActions from "@carrier/fds-react/DialogActions";
import DialogContent from "@carrier/fds-react/DialogContent";
import DialogFds from "@carrier/fds-react/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { bootstrapColors } from "@/theme/constants";
export var Dialog = function (_a) {
    var onClose = _a.onClose, open = _a.open, dialogTitle = _a.dialogTitle, content = _a.content, actions = _a.actions, dividers = _a.dividers, rest = __rest(_a, ["onClose", "open", "dialogTitle", "content", "actions", "dividers"]);
    return (React.createElement(DialogFds, __assign({ onClose: onClose, open: open, maxWidth: "xs" }, rest),
        React.createElement(DialogTitle, null,
            dialogTitle,
            React.createElement(IconButton, { "aria-label": "Close", onClick: function (e) {
                    if (onClose) {
                        onClose(e, "backdropClick");
                    }
                }, sx: {
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: bootstrapColors.Secondary.main,
                } },
                React.createElement(CloseIcon, null))),
        React.createElement(DialogContent, { dividers: dividers }, content),
        actions && (React.createElement(DialogActions, { sx: { padding: "20px" } }, actions))));
};
